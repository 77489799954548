<template>
  <v-card
    :elevation="elevation"
    class="card ma-0 pa-0"
    style="position: relative"
    tile
    :key="keyId"
  >
    <div
      class="white--text"
      style="
        position: absolute;
        background: #eb5757;
        width: 80px;
        height: 30px;
        left: 10px;
        z-index: 10;
        border-radius: 6px;
      "
      v-if="item.sku.product.percentage > 0"
    >
      <p class="mt-1">
        <strong>{{ item.sku.product.percentage }}%</strong>
      </p>
    </div>

    <div
      class="white--text"
      style="
        position: absolute;
        background: #eb5757;
        width: 80px;
        height: 30px;
        left: 10px;
        z-index: 10;
        border-radius: 6px;
      "
      v-else-if="item.promotion"
    >
      <p class="mt-1">
        <strong v-if="item.promotion.discountType === percentual" >{{ item.promotion.discount }}%</strong>
        <strong v-else>
          {{
            item.promotion.discount.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </strong>
      </p>
    </div>

    <v-row class="py-1 ma-0 add-border-bottom">
      <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
        <div class="container-produto-img-name-cod-variacao">
          <div class="container-produto-img">
            <v-img
              class="ma-0 pa-0"
              width="100"
              height="100"
              :src="env_url + item.sku.product.productPic"
              @click="goTo(item)"
            >
            </v-img>
          </div>

          <div class="ma-0 pa-0" style="text-align: start" @click="goTo(item)">
            <div>
              <span class="card-title pa-0 ma-0">{{
                item.sku.product.productName
              }}</span>

              <br />
              <span class="product-cod">
                Cód: {{ item.sku.product.productCode }}
              </span>
            </div>

            <v-row
              class="variacoes text--primary pa-0 ma-0"
              v-for="(attribute, attributesId) in atributosBroduto"
              :key="attributesId"
            >
              <div class="atributos">
                <p class="span-btnCard px-1">{{ attribute.name }}</p>
              </div>
            </v-row>
          </div>
        </div>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
        <div class="container-produto-quantidade-preco-btn align-center">
          <div class="hidden-md-and-up">
            <div class="excluir" @click="deleteDialog = true">Excluir</div>
          </div>

          <div class="minusplusnumber">
            <div>
              <v-icon small class="mpbtn minus" v-on:click="mpminus(item)"
                >mdi-minus</v-icon
              >
            </div>

            <div id="field_container">
              <input
                :key="componentKey"
                v-mask="'#####'"
                type="number"
                style="font-style: normal; font-weight: bold; color: black"
                v-model="newValue"
                disabled
              />
            </div>
            <div>
              <v-icon small class="mpbtn plus" v-on:click="mpplus(item)"
                >mdi-plus</v-icon
              >
            </div>
          </div>

          <div class="container-produto-preco-btn">
            <div v-if="item.sku.product.percentage > 0">
              <span>
                A partir
                <span style="text-decoration: line-through">
                  de:
                  {{
                    item.sku.differentValue.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </span>
              </span>
            </div>

            <div v-else-if="item.promotion">
              <span>
                A partir
                <span style="text-decoration: line-through">
                  de:
                  {{
                    item.sku.differentValue.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </span>
              </span>
            </div>

            <span class="card-price px-0 mx-0">
              {{
                calculatePrice.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </span>

            <div
              class="excluir hidden-sm-and-down"
              @click="deleteDialog = true"
            >
              Excluir
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- Dialog DELETE  -->
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="deleteDialog"
      max-width="400px"
    >
      <DeleteModal
        title="Remover produtos"
        textMessage="Tem certeza que deseja remover os itens desse produto do carrinho?"
        cancelBtn="Cancelar"
        okbtn="Sim"
        textObservation=""
        icone="mdi-trash-can-outline"
        @close="deleteDialog = false"
        @action="(deleteDialog = false), removeOne(item, 0)"
        :key="dialogKey"
      />
    </v-dialog>
  </v-card>
</template>

<style scoped>
.add-border-bottom {
  border-bottom: 1px solid #9c9c9c;
}
.atributos {
  background: #2d3038;
  margin: 10px 10px 0 0;
  border-radius: 3px;
}

.variacoes {
  display: inline-block;
  padding: 10px;
}

.container-produto-img-name-cod-variacao {
  display: flex;
}

.container-produto-img {
  padding: 0 10px 0 0;
}

.container-produto-quantidade-preco-btn {
  display: flex;
  justify-content: space-between;
}

.container-produto-preco-btn {
  text-align: right;
}

.card:hover {
  background-color: #ddd;
}

.excluir {
  cursor: pointer;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;

  color: #bc0000;
}

.v-responsive {
  width: 100px !important;
  height: 100px !important;
}

.minusplusnumber {
  border: 1px solid silver;
  border-radius: 10px !important;
  background-color: #fff;
  margin: 0 5px 0 5px;
  display: inline-flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap !important;
}

.minusplusnumber #field_container input {
  width: 50px;
  text-align: center;
  font-size: 15px;
  padding: 3px;
  border: none;
}

.minusplusnumber .mpbtn {
  padding: 3px 10px 3px 10px;
  cursor: pointer;
  border-radius: 10px !important;
  color: #bc0000;
}

.minusplusnumber .mpbtn:hover {
  background-color: #ddd;
}

.minusplusnumber .mpbtn:active {
  background-color: #c5c5c5;
}

.card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 0px;
  letter-spacing: 0.15px;
  color: #000000;
}

.product-cod {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}

.card-subprice {
  font-family: Poppins;
  font-style: normal;
  font-size: 0.9rem;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: #979797;
}

.card-price {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 27px;

  letter-spacing: 0.15px;

  color: #000000;
}

.card-inside {
  display: flex;
  width: fit-content;
  justify-content: center;
  height: 26px;
  background-color: #2d3038;
}

.span-btnCard {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #dadada;
  padding: 3px;
}

@media (max-width: 500px) {
  .container-produto-quantidade-preco-btn {
    flex-wrap: wrap !important;
  }
}
</style>

<script>
import { thisExpression } from "@babel/types";
import ApiService from "../../../services/ApiService";
import DeleteModal from "./DeleteModal.vue";
import moment from "moment";
export default {
  name: "ProductCardDesktop",
  props: {
    keyId: Number,
    elevation: Number,
    item: Object,
    value: {
      default: 0,
      type: Number,
    },
    min: {
      default: 0,
      type: Number,
    },
    max: {
      default: undefined,
      type: Number,
    },
  },

  data: () => ({
    dialogKey: false,
    deleteDialog: false,
    componentKey: 1,
    cartProducts: [],
    newValue: 0,
    percentage: 0,
    env_url: process.env.VUE_APP_BASE_URL,
    loading: false,
    apiService: new ApiService(),
    atributosBroduto: [],
    promotion: null,
    percentual: 1,
  }),

  components: {
    DeleteModal,
  },

  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.newValue = this.item.quantity;
    this.atributosBroduto = this.item.sku.attributes;
    this.updateCart();
  },

  computed: {
    calculatePrice() {
      let discont = 0
      if(this.item.sku.product.percentage > 0) {
        discont = (this.item.sku.product.percentage / 100) * this.item.sku.differentValue
      } else if (this.item.promotion){
        discont = this.item.promotion.discountType === this.percentual ? (this.item.promotion.discount / 100) * this.item.sku.differentValue : discont = this.item.promotion.discount
      }
      return this.item.quantity * (this.item.sku.differentValue - discont);
    },
  },

  methods: {
    mpminus(item) {
      if (this.newValue > 1) {
        this.newValue--;
        this.removeOne(item);
      } else if (this.newValue == 1) {
        this.newValue = 1;
        this.deleteDialog = true;
        // this.removeItem(item);
      }
    },

    mpplus(item) {
      if (this.newValue < 999) {
        this.newValue++;
        this.componentKey++;
        this.addOne(item);
      } else if (this.newValue == 999) {
        this.newValue = 999;
      }
    },

    addOne(item) {
      item.quantity++;
      this.apiService
        .put("cart/edit", item)
        .then(() => {
          this.$root.$emit("changeBadge", this.badge);
          this.$emit("quoteShipping");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      // let productFind = this.cart.find((e) => e.skuId === product.skuId);

      // if (productFind) {
      //   var index = this.cart.indexOf(productFind);
      //   productFind.qty++;
      //   this.cart[index] = productFind;
      // } else {
      // }

      // localStorage.setItem("cartUser", JSON.stringify(this.cart));
      // this.$root.$emit("changeCart", this.cart);

      // this.badge = this.cart.reduce((total, e) => {
      //   return total + e.qty;
      // }, 0);

      // localStorage.setItem("cartBadge", JSON.stringify(this.badge));
      // this.$root.$emit("changeBadge", this.badge);
    },

    removeOne(item, qty) {
      if (qty === 0) {
        item.quantity = 0;
      } else {
        item.quantity--;
      }
      this.apiService
        .put("cart/edit", item)
        .then(() => {
          if (item.quantity == 0) {
            this.$emit("remove");
          }
          this.$root.$emit("changeBadge", this.badge);
          this.$emit("quoteShipping");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });

      // let productFind = this.cart.find((e) => e.skuId === product.skuId);
      // if (productFind) {
      //   var index = this.cart.indexOf(productFind);
      //   productFind.qty--;
      //   this.cart[index] = productFind;
      // } else {
      // }
      // localStorage.setItem("cartUser", JSON.stringify(this.cart));
      // this.$root.$emit("changeCart", this.cart);

      // this.badge = this.cart.reduce((total, e) => {
      //   return total + e.qty;
      // }, 0);
      // localStorage.setItem("cartBadge", JSON.stringify(this.badge));
      // this.$root.$emit("changeBadge", this.badge);
    },

    updateCart() {
      const cartFromLocalStorage = JSON.parse(
        localStorage.getItem("cartUser") || "[]"
      );
      if (cartFromLocalStorage) {
        this.cart = cartFromLocalStorage;
      }
    },

    removeItem(item) {
      var findProduct = this.cart.find((e) => e.productId == item.productId);
      if (findProduct !== undefined) {
        var index = this.cart.indexOf(findProduct);
        this.cart.splice(index, 1);
      }

      //update Cart LocalStorage
      localStorage.setItem("cartUser", JSON.stringify(this.cart)); //set
      this.$root.$emit("changeCart", this.cart); //emit

      //update Badge LocalStorage
      this.badge = this.cart.reduce((total, item) => {
        return total + item.qty;
      }, 0);
      localStorage.setItem("cartBadge", JSON.stringify(this.badge));
      this.$root.$emit("changeBadge", this.badge);

      this.componentKey += 1;
    },

    async goTo(item) {
      await this.$router.push({
        path: "/product?id=" + item.sku.productId,
        params: item,
      });
    },
  },
};
</script>
