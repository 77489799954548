<template>
  <v-card
      :elevation="elevation"
      min-height="100%"
      style="position: relative"
      height="100%"
      class="ma-0 pa-0 product"
      outlined
      @click="action()"
      @mouseover="hoverImage = true"
      @mouseout="hoverImage = false"
  >
    <div class="white--text discount" v-if="item.percentage > 0">
      <p class="mt-1">
        <strong>{{ item.percentage }}%</strong>
      </p>
    </div>

    <div class="white--text discount" v-else-if="promotion">
      <p class="mt-1">
        <strong v-if="promotion.promotion.discountType === percentual" >{{ promotion.promotion.discount }}%</strong>
        <strong v-else>
          {{
            promotion.promotion.discount.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </strong>
      </p>
    </div>

    <div
        style="height: 100% !important"
        class="d-flex flex-column justify-space-between"
    >
      <div class="pa-1">
        <transition>
          <v-img
              v-if="!hoverImage"
              class="white--text align-end img"
              width="100%"
              height="180"
              :src="env_url + item.productPic"
          ></v-img>

          <v-img
              v-else-if="
              hoverImage &&
              item.images.length > 0 &&
              item.images.find((x) => x.path !== item.productPic)
            "
              class="white--text align-end img"
              width="100%"
              height="180"
              :src="env_url + item.images.find((x) => x.path !== item.productPic).path"
          >
          </v-img>
        </transition>
        <v-card-title class="card-title pl-1">
          {{ formatName(item.productName) }}
          <br/>
        </v-card-title>
      </div>

      <v-container cols="12" class="pa-0 ma-0 text-start">
        <div class="px-2" v-if="item.percentage > 0">
          <div>
            <span>
              A partir
              <span class="card-subprice" style="text-decoration: line-through; color: #6D7989!important; font-weight: normal;">
                de:
                {{
                  shortPriceSku.differentValue.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </span>
            </span>
          </div>

          <div class="d-flex align-end px-2 ">
            <strong class="mb-1"> Por: </strong>
            <h2 class="ml-1 mb-0 card-subprice">
              {{
                shortPriceSku ? (shortPriceSku.differentValue - (item.percentage / 100) * shortPriceSku.differentValue).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                })
                : ""
              }}
            </h2>
          </div>
        </div>

        <div class="px-2" v-else-if="promotion">
          <div>
            <span>
              A partir
              <span class="card-subprice" style="text-decoration: line-through; color: #6D7989!important; font-weight: normal;">
                de:
                {{
                  shortPriceSku.differentValue.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </span>
            </span>
          </div>

          <div class="d-flex align-end px-2 ">
            <strong class="mb-1"> Por: </strong>
            <h2 v-if="promotion.promotion.discountType === percentual" class="ml-1 mb-0 card-subprice">
              {{
                shortPriceSku ? (shortPriceSku.differentValue - (promotion.promotion.discount / 100) * shortPriceSku.differentValue).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                })
                : ""
              }}
            </h2>

            <h2 v-else class="ml-1 mb-0 card-subprice">
              {{
                shortPriceSku ? (shortPriceSku.differentValue - promotion.promotion.discount).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                })
                : ""
              }}
            </h2>
          </div>
        </div>

        <div v-else class="d-flex align-end px-2 ">
          <strong class="mb-1"> Por: </strong>
          <h2 class="ml-1 mb-0 card-subprice">
            {{
              shortPriceSku ?  shortPriceSku.differentValue.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
              : ""
            }}
          </h2>
        </div>

        <div class="d-flex align-center px-2 parcelas">
          <span style="font-size: 12px">
            Em até 3 vezes sem juros ou em até 5 vezes com juros
          </span>
        </div>

        <v-row cols="12" class="px-2">
          <v-col class="d-flex align-end" cols="12" sm="12" md="12" lg="12">
            <button
                class="add-btn"
                @click="action()"
            >
              <svg width="19" height="19" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.5 16C17.0304 16 17.5391 16.2107 17.9142 16.5858C18.2893 16.9609 18.5 17.4696 18.5 18C18.5 18.5304 18.2893 19.0391 17.9142 19.4142C17.5391 19.7893 17.0304 20 16.5 20C15.9696 20 15.4609 19.7893 15.0858 19.4142C14.7107 19.0391 14.5 18.5304 14.5 18C14.5 16.89 15.39 16 16.5 16ZM0.5 0H3.77L4.71 2H19.5C19.7652 2 20.0196 2.10536 20.2071 2.29289C20.3946 2.48043 20.5 2.73478 20.5 3C20.5 3.17 20.45 3.34 20.38 3.5L16.8 9.97C16.46 10.58 15.8 11 15.05 11H7.6L6.7 12.63L6.67 12.75C6.67 12.8163 6.69634 12.8799 6.74322 12.9268C6.79011 12.9737 6.8537 13 6.92 13H18.5V15H6.5C5.96957 15 5.46086 14.7893 5.08579 14.4142C4.71071 14.0391 4.5 13.5304 4.5 13C4.5 12.65 4.59 12.32 4.74 12.04L6.1 9.59L2.5 2H0.5V0ZM6.5 16C7.03043 16 7.53914 16.2107 7.91421 16.5858C8.28929 16.9609 8.5 17.4696 8.5 18C8.5 18.5304 8.28929 19.0391 7.91421 19.4142C7.53914 19.7893 7.03043 20 6.5 20C5.96957 20 5.46086 19.7893 5.08579 19.4142C4.71071 19.0391 4.5 18.5304 4.5 18C4.5 16.89 5.39 16 6.5 16ZM15.5 9L18.28 4H5.64L8 9H15.5Z"
                    fill="#BC0000"/>
              </svg>

              <span class="ml-2 span-btnAdd">Comprar agora</span>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<style scoped>
.img {
  border-radius: 5px;
}
.discount{
  position: absolute;
  background: #BC0000;
  width: 80px;
  height: 30px;
  top: 0;
  right: 5%;
  z-index: 10;
  text-align: center;
  align-self: center;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.container-price {
  text-align-last: left;
  margin-top: 10px !important;
  margin-bottom: -10px !important;
}

.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-weight: 600 !important;
  min-height: 32px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word !important;
  font-size: 16px !important;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #2D3038;
}

.card-subprice {
  font-size: 16px!important;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #2D3038!important;
}

.parcelas{
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #6D7989;
}

.card-price {
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px;
}

.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
  padding: 3px 3px !important;
}

.add-btn {
  display: flex;
  width: 100%;
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #BC0000;
  background: #FFF;
}

.add-btn:hover > svg > path {
  fill: #ffffff;
}

.add-btn:hover {
  background: #BC0000;
}

.span-btnAdd {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #BC0000
}

.add-btn:hover > .span-btnAdd {
  color: #FFFFFF
}
.product {
  width: 280px!important;
}
@media screen and (max-width: 700px) {
  .product {
    min-width: 160px!important;
  }
  .span-btnAdd {
    font-size: 14px;
  }
}

</style>

<script>
import ApiService from "../../../services/ApiService";
import FormatText from "../../../Utils/FormatText";
import moment from "moment";
import {throwStatement} from "@babel/types";

export default {
  name: "ImageCard",

  props: {
    elevation: Number,
    item: Object,
    textMessage: String,
    cancelBtn: String,
    okbtn: String,
    env_url: String,
  },

  data: () => ({
    hoverImage: false,
    loading: false,
    shortPriceSku: 0,
    apiService: new ApiService(),
    percentage: 0,
    promotion: null,
    percentual: 1,
  }),

  created() {
    const [promotion] = this.item.promotions
    this.promotion = promotion
    this.shortPriceSku = this.item.skus
        .sort((a, b) => a.differentValue - b.differentValue)
        .filter((x) => x.differentValue > 0 && !x.delete_at)[0];
  },

  methods: {
    formatName(productName) {
      return FormatText.capitalizeFirst(productName)
    },
    descriptionSku(sku) {
      if (!sku.attributes) {
        return ``;
      }
      const atributoName = sku.attributes.find(
          (x) => x.attribute.type === "Texto"
      );
      if (atributoName) {
        return `${atributoName.name} - `;
      }
      const atributoCor = sku.attributes.find(
          (x) => x.attribute.type === "Cor"
      );
      if (atributoCor) {
        return `${atributoCor.name} - `;
      }
      return "";
    },
    close() {
      this.$emit("close");
    },

    action() {
      this.$emit("action");
    },
  },
};
</script>
