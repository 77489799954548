<template>
  <v-card
      class="mx-auto"
      width="95%"
      elevation="1"
      color="#FFFFFF"
      v-if="!loading"
  >
    <v-row cols="12" class="ma-0 pa-1 ">
      <v-col class=" " cols="12">
        <v-carousel
            :key="componentKey"
        >
          <v-carousel-item
              v-for="(image, i) in product.images"
              :key="i"
          >
            <v-img
                class="carousel-item-img"
                :src="`${env_url}${image.path}`"
                :alt="productName"
                :title="productName"
                contain
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>

      <v-col cols="12">
        <section class="d-flex align-start justify-space-between">
          <p class="productName">
            <strong>
              {{ productName }}
            </strong>
          </p>

          <section class=" ml-5">
            <a @click="shareProduct()">
              <svg width="58" height="60" viewBox="0 0 58 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="57" height="59" rx="28.5" fill="white"/>
                <rect x="0.5" y="0.5" width="57" height="59" rx="28.5" stroke="#DEDEDE"/>
                <path d="M35 40C34.1667 40 33.4583 39.7083 32.875 39.125C32.2917 38.5417 32 37.8333 32 37C32 36.8833 32.0083 36.7623 32.025 36.637C32.0417 36.5117 32.0667 36.3993 32.1 36.3L25.05 32.2C24.7667 32.45 24.45 32.646 24.1 32.788C23.75 32.93 23.3833 33.0007 23 33C22.1667 33 21.4583 32.7083 20.875 32.125C20.2917 31.5417 20 30.8333 20 30C20 29.1667 20.2917 28.4583 20.875 27.875C21.4583 27.2917 22.1667 27 23 27C23.3833 27 23.75 27.071 24.1 27.213C24.45 27.355 24.7667 27.5507 25.05 27.8L32.1 23.7C32.0667 23.6 32.0417 23.4877 32.025 23.363C32.0083 23.2383 32 23.1173 32 23C32 22.1667 32.2917 21.4583 32.875 20.875C33.4583 20.2917 34.1667 20 35 20C35.8333 20 36.5417 20.2917 37.125 20.875C37.7083 21.4583 38 22.1667 38 23C38 23.8333 37.7083 24.5417 37.125 25.125C36.5417 25.7083 35.8333 26 35 26C34.6167 26 34.25 25.9293 33.9 25.788C33.55 25.6467 33.2333 25.4507 32.95 25.2L25.9 29.3C25.9333 29.4 25.9583 29.5127 25.975 29.638C25.9917 29.7633 26 29.884 26 30C26 30.1167 25.9917 30.2377 25.975 30.363C25.9583 30.4883 25.9333 30.6007 25.9 30.7L32.95 34.8C33.2333 34.55 33.55 34.3543 33.9 34.213C34.25 34.0717 34.6167 34.0007 35 34C35.8333 34 36.5417 34.2917 37.125 34.875C37.7083 35.4583 38 36.1667 38 37C38 37.8333 37.7083 38.5417 37.125 39.125C36.5417 39.7083 35.8333 40 35 40Z" fill="#BC0000"/>
              </svg>
            </a>
          </section>
        </section>

        <section>
          <p class="productCode">Cód: {{ product.productCode }}</p>
        </section>

        <section class="container-reviews d-flex" v-if="product.reviews && product.reviews.length > 0">
          <section class="d-flex align-center justify-center container-reviews-star mr-4">
            <v-icon @click="shareProduct()" size="20" color="#E2B100">mdi-star-outline</v-icon>
            <span class="ml-1 note">{{ noteReview }}</span>
          </section>

          <section class="d-flex align-center justify-center container-reviews-evaluations "
                   @click="goToReviews($event)">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5.75 7C5.75 7.06631 5.72366 7.12989 5.67678 7.17678C5.62989 7.22366 5.5663 7.25 5.5 7.25C5.4337 7.25 5.37011 7.22366 5.32322 7.17678C5.27634 7.12989 5.25 7.06631 5.25 7C5.25 6.9337 5.27634 6.87011 5.32322 6.82322C5.37011 6.77634 5.4337 6.75 5.5 6.75C5.5663 6.75 5.62989 6.77634 5.67678 6.82322C5.72366 6.87011 5.75 6.9337 5.75 7ZM5.75 7H5.5M8.25 7C8.25 7.06631 8.22366 7.12989 8.17678 7.17678C8.12989 7.22366 8.0663 7.25 8 7.25C7.9337 7.25 7.87011 7.22366 7.82322 7.17678C7.77634 7.12989 7.75 7.06631 7.75 7C7.75 6.9337 7.77634 6.87011 7.82322 6.82322C7.87011 6.77634 7.9337 6.75 8 6.75C8.0663 6.75 8.12989 6.77634 8.17678 6.82322C8.22366 6.87011 8.25 6.9337 8.25 7ZM8.25 7H8M10.75 7C10.75 7.06631 10.7237 7.12989 10.6768 7.17678C10.6299 7.22366 10.5663 7.25 10.5 7.25C10.4337 7.25 10.3701 7.22366 10.3232 7.17678C10.2763 7.12989 10.25 7.06631 10.25 7C10.25 6.9337 10.2763 6.87011 10.3232 6.82322C10.3701 6.77634 10.4337 6.75 10.5 6.75C10.5663 6.75 10.6299 6.77634 10.6768 6.82322C10.7237 6.87011 10.75 6.9337 10.75 7ZM10.75 7H10.5M1.5 9.00667C1.5 10.0733 2.24867 11.0027 3.30467 11.158C4.02933 11.2647 4.76133 11.3467 5.5 11.404V14.5L8.28933 11.7113C8.42744 11.5738 8.61312 11.4945 8.808 11.49C10.1091 11.458 11.407 11.3471 12.6947 11.158C13.7513 11.0027 14.5 10.074 14.5 9.006V4.994C14.5 3.926 13.7513 2.99733 12.6953 2.842C11.1406 2.61381 9.57135 2.49951 8 2.5C6.40533 2.5 4.83733 2.61667 3.30467 2.842C2.24867 2.99733 1.5 3.92667 1.5 4.994V9.006V9.00667Z"
                  stroke="#6D7989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <span class="ml-2 note">
              {{ product.reviews.length }}
              {{ product.reviews.length > 1 ? " Avaliações" : " Avaliação" }}</span>
          </section>
        </section>

        <section class="line my-7" v-if="product.reviews && product.reviews.length > 0"></section>

        <section>
          <section class="ml-0" v-if="product.skuId && percentage > 0">
            <span class="price-discount">
              {{
                calculatePriceNoDiscount().toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
           </span>
          </section>

          <section class="ml-0" v-else-if="product.skuId && promotion">
            <span class="price-discount">
              {{
                calculatePriceNoDiscount().toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </span>
          </section>

          <section class="my-2" v-if="product.skuId">
            <span v-if="product.skuId" class="price">
              {{
                calculatePrice().toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </span> <br/>
          </section>

          <section v-if="product.skuId">
            <span class="parcelamento"> Em até 3x sem juros</span>
          </section>
        </section>

        <section class="line my-7"></section>

        <v-row v-for="(attr, i) in attributes" :key="i">
          <v-col
              style="margin-top: -10px !important"
              cols="12" md="12" sm="12"
              v-if="attr.atributo.attribute.type === 'Cor'"
          >
          <span class="label">
            {{ attr.atributo.attribute.name }}
          </span>
            <!-- Atributos de Cor -->
            <v-sheet
                class="transparent-background mx-auto py-0"
                elevation=""
                max-width="100%"
            >
              <v-slide-group
                  v-model="model"
                  class="pa-0 MA-0"
                  center-active
                  show-arrows
              >
                <v-slide-item
                    v-for="(itens, idx) in attr.itensAtributos"
                    :key="idx"
                    :class="selecionado(itens) ? 'btn-scroll-pressed' : ''"
                >
                  <v-card
                      :color="itens.hex"
                      class="btn-scroll ma-1 my-0 PA-0"
                      height="40"
                      width="40"
                      :key="componentKey"
                      @click="selectIndexColor(itens, attr)"
                  >
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>


          <!-- Atributos de Texto -->
          <v-col class="py-0" v-if="attr.atributo.attribute.type === 'Texto'">
              <span class="attributeName">
                {{ attr.atributo.attribute.name }}:
              </span>
            <v-sheet
                style="margin-left: -5px!important"
                class="transparent-background mx-auto py-0 mt-2"
                elevation=""
                max-width="100%"
            >
              <v-slide-group
                  v-model="model2"
                  center-active
                  multiple
                  show-arrows
              >
                <v-slide-item
                    v-for="(itens, idx) in attr.itensAtributos"
                    :key="idx"
                    :class="selecionado(itens) ? 'attr-item-pressed' : ''"
                >
                  <v-card
                      elevation="0"
                      :key="componentKey"
                      class="attr-item ma-1 my-0 PA-0"
                      @click="selectIndex(itens, attr)"
                  >
                    {{ itens.name }}
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>

        <section>
          <section class="semSkuSelecionado mb-1 mt-5 ">
            <span v-if="product.calculator">Selecione os metros (comprimento)</span>
            <span v-else>Selecione a variação</span>
          </section>

          <section class="container-quantity-purchase-add-cart">
            <section class="d-flex px-1 quantity">
              <v-text-field
                  dense
                  full-width
                  height="50"
                  outlined
                  v-mask="'####'"
                  class="primary-input-b2c  "
                  style="text-align: center!important; margin-left: -4px"
                  v-model="quantity"
              >
                <template v-slot:prepend-inner>
                  <v-icon class="mt-2" color="#bc0000" size="20" @click="mpminus()">mdi-minus</v-icon>
                </template>
                <template v-slot:append>
                  <v-icon class="mt-2" color="#bc0000" size="20" @click="mpplus()">mdi-plus</v-icon>
                </template>
              </v-text-field>
            </section>

            <section class="btn-purchase-container">
              <v-btn
                  class="btn-purchase"
                  elevation="0"
                  height="50"
                  width="100%"
                  @click="purchase(product)"
                  color="#1cbb56"
              >
                Comprar
              </v-btn>
            </section>

            <section class="btn-add-cart-container">
              <v-btn
                  @click="addCart(product)"
                  color="#bc0000"
                  class="btn-add-cart "
                  elevation="0"
                  width="100%"
                  height="50"
              >
                <section class="d-flex align-center">
                  <v-icon class=" " color="#FFFFFF" size="20" @click="mpplus()">mdi-cart-outline</v-icon>
                  <span class="ml-1">Adicionar ao carrinho</span>
                </section>
              </v-btn>
            </section>
          </section>
        </section>
      </v-col>
    </v-row>

    <v-row col="12" class="mx-2 mb-1">
      <v-col cols="12">
        <v-btn
            :href="linkWhatsApp"
            target="_blank"
            class="btn-whatsapp ma-0 pa-0"
            elevation="0"
            height="50"
            width="100%"
            color="#1cbb56"
        >
          <section class="d-flex align-center flex-wrap">
            <v-icon class="pr-1" size="25">mdi-whatsapp</v-icon>
            <span>
                Tire suas dúvidas conosco no Whatsapp
              </span>
          </section>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels
            v-if="product.calculator"
            v-model="panel"
            class="elevation-0 calulator"
        >
          <v-expansion-panel>
            <v-expansion-panel-header style="height: 50px!important;">
              <section class="d-flex align-center justify-space-between">
                <v-icon class="mr-2" color="#4F4F4F" size="28" @click="mpminus()">mdi-calculator</v-icon>
                <span class="calulator-text">Quantos metros são necessários? <span class="calulator-text-red">Calcule aqui!</span></span>
              </section>

              <template v-slot:actions>
                <v-icon size="32">
                  mdi-chevron-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <calculadora
                  :sku="product.sku"
                  @total="(payload) => (quantity = payload > 0 ? payload : quantity)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row col="12" class="ma-0 pa-0 mx-3 mb-10">
      <v-col cols="12" class="ma-0 pa-0">
        <v-expansion-panels class="border-top">
          <v-expansion-panel>
            <v-expansion-panel-header class="tab">
              <span>Descrição Geral</span>
              <template v-slot:actions>
                <v-icon size="40">
                  mdi-chevron-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <section style="margin-top: -15px">
                <p class="text-description">
                  <strong>Descrição: </strong>
                  <span v-html="product.productDescription"></span>
                </p>
              </section>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="border-top">
          <v-expansion-panel>
            <v-expansion-panel-header class="tab">
              <span>Especificações</span>
              <template v-slot:actions>
                <v-icon size="40">
                  mdi-chevron-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <section style="margin-top: -15px">
                <p class="text-description mb-1" >
                  <strong>Material: </strong>
                  <span v-html="product.productApplication"></span>
                </p>

                <p class="text-description mb-1">
                  <strong>Espessura: </strong>
                  <span v-html="product.productType"></span>
                </p>

                <p class="text-description mb-1">
                  <strong>Acabamento: </strong>
                  <span v-html="product.productGlue"></span>
                </p>

                <p class="text-description mb-1">
                  <strong>Tipo de cola: </strong>
                  <span v-html="product.productLiner"></span>
                </p>
              </section>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="border-top">
          <v-expansion-panel>
            <v-expansion-panel-header class="tab">
              <span>Durabilidade</span>
              <template v-slot:actions>
                <v-icon size="40">
                  mdi-chevron-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <section style="margin-top: -15px">
                <p class="text-description">
                  <strong>Durabilidade: </strong>
                  <span v-html="product.productDurability"></span>
                </p>
              </section>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="border-top">
          <v-expansion-panel>
            <v-expansion-panel-header class="tab">
              <span>Vantagens</span>
              <template v-slot:actions>
                <v-icon size="40">
                  mdi-chevron-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <section style="margin-top: -15px">
                <p class="text-description mb-1">
                  <strong>Vantagens: </strong>
                  <span v-html="product.productIndicator"></span>
                </p>
              </section>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="border-top border-bottom">
          <v-expansion-panel>
            <v-expansion-panel-header class="tab">
              <span>Formas de Pagamento</span>
              <template v-slot:actions>
                <v-icon size="40">
                  mdi-chevron-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row cols="12" class="ma-0 pa-0" dense style="margin-top: -20px!important; margin-left: -6px!important">
                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="ma-0 pa-0">
                  <v-card
                      height="150"
                      class="pa-3 mt-1 pb-4 d-flex flex-column my-1 paymentMethod"
                      outlined
                      flat
                  >
                    <section class="d-flex align-center">
                      <v-icon
                          class="mr-2"
                          size="25"
                          color="#4F4F4F"
                      >
                        mdi-credit-card-outline
                      </v-icon>

                      <span class="paymentMethod-title ">
                      Cartão de crédito
                    </span>
                    </section>

                    <section class="my-3">
                      <span class="paymentMethod-sub-title"> Em até 3x sem juros</span>
                    </section>

                    <section class="d-flex align-center">
                      <section class="d-flex">
                        <v-img
                            class="mr-2 brand"
                            src="../../../assets/Visa.svg"
                            width="43"
                            height="30"
                            contain
                        ></v-img>

                        <v-img
                            class="mr-2 brand"
                            src="../../../assets/Amex.svg"
                            width="43"
                            height="30"
                            contain
                        ></v-img>

                        <v-img
                            class="mr-2 brand"
                            src="../../../assets/Mastercard.svg"
                            width="43"
                            height="30"
                            contain
                        ></v-img>

                        <v-img
                            class="mr-2 brand"
                            src="../../../assets/disners.jpg"
                            width="43"
                            height="30"
                            contain
                        ></v-img>
                      </section>
                      <span class="paymentMethod-sub-title ml-3">e mais...</span>
                    </section>
                  </v-card>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="ma-0 pa-0">
                  <v-card
                      height="150"
                      class="pa-3 pt-9 mt-1 d-flex flex-column  paymentMethod"
                      outlined
                      flat
                  >
                    <section class="d-flex align-center">
                      <section class="mr-2 mt-1">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M16.1171 17.0198L13.1071 14.0098C12.9971 13.8998 12.8671 13.8798 12.7971 13.8798C12.7271 13.8798 12.5971 13.8998 12.4871 14.0098L9.46715 17.0298C9.12715 17.3698 8.59715 17.9198 6.82715 17.9198L10.5371 21.6198C11.0996 22.1816 11.8621 22.4972 12.6571 22.4972C13.4522 22.4972 14.2146 22.1816 14.7771 21.6198L18.4971 17.9098C17.5871 17.9098 16.8271 17.7298 16.1171 17.0198ZM9.46715 7.9698L12.4871 10.9898C12.5671 11.0698 12.6871 11.1198 12.7971 11.1198C12.9071 11.1198 13.0271 11.0698 13.1071 10.9898L16.0971 7.9998C16.8071 7.2598 17.6171 7.0898 18.5271 7.0898L14.8071 3.3798C14.2446 2.818 13.4822 2.50244 12.6871 2.50244C11.8921 2.50244 11.1296 2.818 10.5671 3.3798L6.85715 7.0798C8.61715 7.0798 9.15715 7.6598 9.46715 7.9698Z"
                              fill="#4F4F4F"/>
                          <path
                              d="M21.7773 10.3498L19.5273 8.08982H18.2673C17.7273 8.08982 17.1873 8.30982 16.8173 8.69982L13.8173 11.6998C13.5373 11.9798 13.1673 12.1198 12.7973 12.1198C12.4163 12.1145 12.0516 11.9643 11.7773 11.6998L8.75728 8.66982C8.37728 8.28982 7.85728 8.06982 7.30728 8.06982H5.83728L3.54728 10.3698C2.98548 10.9323 2.66992 11.6948 2.66992 12.4898C2.66992 13.2848 2.98548 14.0473 3.54728 14.6098L5.83728 16.9098H7.31728C7.85728 16.9098 8.37728 16.6898 8.76728 16.3098L11.7873 13.2898C12.0673 13.0098 12.4373 12.8698 12.8073 12.8698C13.1773 12.8698 13.5473 13.0098 13.8273 13.2898L16.8373 16.2998C17.2173 16.6798 17.7373 16.8998 18.2873 16.8998H19.5473L21.7973 14.6398C22.3614 14.0672 22.676 13.2945 22.6723 12.4907C22.6685 11.6869 22.3468 10.9172 21.7773 10.3498Z"
                              fill="#4F4F4F"/>
                        </svg>

                      </section>

                      <span class="paymentMethod-title ">
                      Pix
                    </span>
                    </section>

                    <section class="my-3">
                      <span class="paymentMethod-sub-title"> Aprovação imediata do pagamento</span>
                    </section>
                  </v-card>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="ma-0 pa-0">
                  <v-card
                      height="150"
                      class="pa-3 pt-9 mt-1 d-flex flex-column my-1 paymentMethod"
                      outlined
                      flat
                  >
                    <section class="d-flex align-center">
                      <section class="mr-2 mt-1">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M1.33301 19V5H3.33301V19H1.33301ZM4.33301 19V5H6.33301V19H4.33301ZM7.33301 19V5H8.33301V19H7.33301ZM10.333 19V5H12.333V19H10.333ZM13.333 19V5H16.333V19H13.333ZM17.333 19V5H18.333V19H17.333ZM20.333 19V5H23.333V19H20.333Z"
                              fill="#4F4F4F"/>
                        </svg>
                      </section>

                      <span class="paymentMethod-title ">
                      Boleto Bancário
                    </span>
                    </section>

                    <section class="my-3">
                      <span class="paymentMethod-sub-title"> 2 a 3 dias úteis para compensação do pagamento</span>
                    </section>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row class="mx-6" v-if="product.reviews && product.reviews.length > 0" style="flex-flow: column; padding: 0 20px">
      <reviews :reviews="product.reviews"/>
    </v-row>

    <v-row class="mx-6">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <h2 class="text-center">Produtos relacionados</h2>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="ma-0 pa-0">
        <div class="list-product-content" v-if="products && products.length > 0">
          <div v-for="item in products" class="ma-1" :key="item.productId">
            <ImageCard
                :key="item.productId"
                :item="item"
                :env_url="env_url"
                @action="goTo(item)"
            />
          </div>
        </div>

        <section>
          <v-pagination
              class="my-4"
              v-model="page"
              :length="totalPages"
              :total-visible="15"
          ></v-pagination>
        </section>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Vue from "vue";
import ApiService from "../../../services/ApiService";
import Calculadora from "./Calculadora";
import Reviews from "./Reviews";
import moment from "moment";
import FormatText from "../../../Utils/FormatText";
import ImageCard from "./ImageCard.vue";
import FormatParamsUrl from "../../../Utils/FormatParamsUrl";

export default {
  name: "Details2ProductDesktop",
  props: {
    productId: String,
  },
  metaInfo() {
    return {
      meta: this.meta,
      title: this.title,
    }
  },
  components: {
    ImageCard,
    Calculadora,
    Reviews,
  },
  data: () => ({
    lastProductId: "",
    note: 0,
    product: [],
    products: [],
    addressIdSelect: "",
    user: [],
    skuSelectedColor: [],
    panel: true,
    listSelectedAttributes: [],
    selectedAttributes: {
      type: "",
      value: "",
      name: "",
    },
    skuSelectedText: [],
    skuSelectedFinal: [],
    revendedores: false,
    key: false,
    componentKey: 1,
    viewSelectedColor: [],
    images: [],
    viewSelected: [],
    readMore1: false,
    readMore2: false,
    isFrete: false,
    endereco: "",
    valor: 0,
    percentage: 0,
    promotion: null,
    percentual: 1,
    quantity: "",
    numberOfAttributesInTheSku: 0,
    cep: "",
    dados: [],
    frete: {},
    colors: [],
    attributes: [],
    attributesSearch: [],
    active: false,
    env_url: "",
    loading: false,
    cepLoading: false,
    apiService: new ApiService(),
    model: null,
    model2: null,
    meta: [],
    title: "",
    activeTab: 0,
    filter: {
      product: null,
      page: 1,
      pageSize: 15,
      selectedSizes: [],
      Subcategories: [],
      orderByPrice: 0,
    },
    pageSize: 20,
    page: 1,
    totalPages: 1,
    totalItems: 0,
  }),

  async created() {
    this.quantity = 1;
    this.env_url = process.env.VUE_APP_BASE_URL;
    this.lastProductId = this.productId;
    this.user = JSON.parse(localStorage.getItem("user"));
    await this.getProduct();
    this.updateCart();
    if (this.listSelectedAttributes && this.listSelectedAttributes.length === 0) {
      await this.selectFirstAttr();
    }
    this.title = FormatText.capitalizeFirst(this.product.productName)
    this.meta = [
      {
        vmid: 'description',
        name: 'description',
        content: this.product.productDescription,
      }
    ]
    await this.getProducts()
    this.scrollTo()
  },

  computed: {
    linkWhatsApp() {
      const Link = window.location.href;
      const text = `Olá Loja Imprimax, tudo bem? Gostaria de mais informações sobre o produto: ${this.productName} - Link: ${Link}`;
      return `https://api.whatsapp.com/send?1=pt_BR&phone=5511945059235&text=${text}`
    },
    productName() {
      return FormatText.capitalizeFirst(this.product.productName)
    },
    noteReview() {
      const notes = this.product.reviews.reduce((total, note) => (total + note.note), 0)
      return Number(notes / this.product.reviews.length).toFixed(1)
    },
    categoryCount() {
      let array = [];
      this.product.categories.forEach((e) => {
        array.push(e.category.categoryName);
      });

      return array.join(", ");
    },
  },

  watch: {
    page() {
      this.filter.page = this.page;
      this.getProducts();
    },
  },
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async getProducts() {
      this.filter.pageSize = this.pageSize;
      this.filter.page = this.page;
      await this.apiService
          .post(`product/listActiveB2C`, this.filter)
          .then((response) => {
            this.totalPages = response.pager.totalPages;
            this.products = response.products;
            this.totalItems = response.pager.totalItems;
            //this.isEmpty = this.products.length === 0;
          });
      this.loading = false;
    },
    async goTo(product) {
      const productName = FormatParamsUrl.format(product.productName);
      this.$emit('go-to', productName)
      this.scrollTo()
      await this.getProduct()
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    shareProduct() {
      try {
        const url = window.location.href;
        if (navigator.share) {
          navigator
              .share({
                title: this.product.productName,
                text: this.product.productName,
                url,
              })
              .then(() => {
              })
              .catch(() => {
              });
        } else {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "",
            message: "Não é possivel compartilhar esse produto.",
            trace: null,
          });
        }
      } catch (e) {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "",
          message: "Não é possivel compartilhar esse produto.",
          trace: null,
        });
      }
    },
    goToReviews(event) {
      event.preventDefault();
      const element = document.querySelector("#ratings");
      const offset = 100;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
      const top = elementRect - bodyRect + scrollTop - offset;
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    calculateAverageRatings() {
      if (this.product.reviews && this.product.reviews.length) {
        this.note =
            this.product.reviews.reduce(
                (accumulator, currentValue) =>
                    Number(accumulator) + Number(currentValue.note),
                0
            ) / this.product.reviews.length;
      }
    },

    async getAddress(user) {
      if (user) {
        this.loading = true;
        await this.apiService
            .get(`client/get-addresses?id=` + user.id)
            .then((response) => {
              this.addresses = response;
              if (this.addresses.length != 0) {
                if (this.addressIdSelect) {
                  this.endereco = this.addresses.find(
                      (e) => e.id === this.addressIdSelect
                  );
                } else {
                  this.endereco = this.addresses.find((e) => e.addressType === 0);
                }
              } else {
                this.goToRoute(`/address?productId=` + this.lastProductId);
              }

              this.loading = false;
            })
            .catch((err) => {
            });

        this.loading = false;
      } else {
        this.goToRoute("/login");
      }
    },

    async atualizarPrice(attr, color, value) {
      this.skuSelectedFinal = [];
      if (attr.type === "Cor") {
        // procurar a cor do sku se for "cor";
        this.skuSelectedColor = [];
        this.product.skus.forEach((e) => {
          let findSkus = e.attributes.find((e) => e.hex === color);
          if (findSkus != null) {
            this.skuSelectedColor.push(e);
          }
        });
      } else if (attr.type === "Texto") {
        // procurar o nome do sku se for "texto";
        this.skuSelectedText = [];
        this.product.skus.forEach((e) => {
          let findSkus = e.attributes.find((e) => e.name === value);
          if (findSkus != null) {
            this.skuSelectedText.push(e);
          }
        });
      }

      this.skuSelectedText.forEach((array1) => {
        this.skuSelectedColor.forEach((array2) => {
          if (array2.id == array1.id) {
            this.skuSelectedFinal.push(array2);
          }
        });
      });

      //ATUALIZAR PREÇO se o vetor achado for único
      /*this.product.finalPrice = this.skuSelectedFinal[0] ? this.skuSelectedFinal[0].differentValue : "";*/
      this.product.skuSelect = this.skuSelectedFinal[0];
      /*await this.calculatePrice();
      await this.getResalesBySkus();*/
      this.componentKey += 1;
    },

    calculatePrice() {
      let discont = 0
      if (this.percentage > 0) {
        discont = (this.percentage / 100) * this.valor
      } else if (this.promotion) {
        discont = this.promotion.promotion.discountType === this.percentual ? (this.promotion.promotion.discount / 100) * this.valor : this.promotion.promotion.discount
      }
      return this.product.skuId ? this.quantity * (this.valor - discont) : this.quantity * this.product.clientPrice;
    },

    calculatePriceNoDiscount() {
      return this.product.skuId ? this.valor : this.product.clientPrice;
    },

    async addCart(product) {
      if (this.listSelectedAttributes.length === this.attributes.length && this.product.skuId) {
        this.loading = true;

        const item = {
          id: 0,
          cartId: 0,
          skuId: product.skuId,
          quantity: parseInt(this.quantity),
          promotionId: this.promotion && this.promotion.promotion ? this.promotion.promotion.id : null,
        };

        if (this.user) {
          await this.apiService
              .post("cart/add", item)
              .then(() => {
                this.$root.$emit("changeBadge");
                this.$toast.success(
                    "Produto adicionado ao carrinho com sucesso!"
                );
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
        } else {
          const find = JSON.parse(localStorage.getItem("cart"));

          const cart = {
            items: [item],
          };

          if (find) {
            let index = find.items.findIndex((x) => x.skuId == item.skuId);
            if (index > -1) {
              let indexOnCart = cart.items.indexOf(item);
              item.quantity += find.items[index].quantity;
              cart.items[indexOnCart] = item;
            } else {
              cart.items.push(...find.items);
            }
          }

          localStorage.setItem("cart", JSON.stringify(cart));
          this.$root.$emit("changeBadge", cart);
          this.$toast.success("Produto adicionado ao carrinho com sucesso!");
          this.loading = false;
        }
      } else {
        this.attributes.forEach((x) => {
          this.$toast.error(x.atributo.attribute.name);
        });
      }
    },

    async purchase(product) {
      await this.addCart(product);

      setTimeout(() => {
        this.goToRoute(`/cart`);
      }, 1000);
    },

    updateCart() {
      const cartFromLocalStorage = JSON.parse(
          localStorage.getItem("cartUser") || "[]"
      );
      if (cartFromLocalStorage) {
        this.cart = cartFromLocalStorage;
        this.badge = this.cart.reduce((total, item) => {
          return total + item.qty;
        }, 0);

        this.totalprice = this.cart.reduce((total, item) => {
          return total + item.qty * item.valor;
        }, 0);
      }

      this.$root.$emit("changeBadge", this.badge);
      localStorage.setItem("cartBadge", JSON.stringify(this.badge));
    },

    mpplus() {
      if (this.quantity < 999) {
        this.quantity++;
      } else {
        this.quantity = 999;
      }
    },

    mpminus() {
      if (this.quantity > 1) {
        this.quantity--;
      } else if (this.quantity == 1) {
        this.quantity = 1;
      }
    },

    async getProduct() {
      this.loading = true;
      const {name} = this.$route.params
      await this.apiService
          .get(`product/get-one-by-name?productName=${name}`)
          .then((response) => {
            this.product = response;
            this.percentage = this.product.percentage
            const [promotion] = this.product.promotions
            this.promotion = promotion
            this.product.reviews = this.product.reviews.sort((a, b) => b.note - a.note);
            this.attributes = []
            this.setAttribues();
            this.colorToFirst();
            this.calculateAverageRatings();
            this.loading = false;
            if (!this.product.categories && this.product.categories.length > 0) {
              this.filter.CategoryName = this.product.categories[0].category.categoryName
            } else if (this.product.subCategories && this.product.subCategories.length > 0) {
              this.filter.productSubCategory = this.product.subCategories[0].subCategory.name
            }
          })
          .catch((err) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Erro ao pesquisar produtos",
              message: err.body ? err.body.message : "Erro ao pesquisar produtos",
              trace: null,
            });
            this.loading = false;
          });
    },

    async getResalesBySkus(skuId) {
      this.loading = true;
      const addressId = this.addresses[0].id;
      let client = new ApiService();
      if (skuId) {
        await client
            .get(
                `resaleproductsku/get-resale?skuId=${skuId}&addressId=${addressId}`
            )
            .then((response) => {
              this.revendedores = response;
              if (this.revendedores) {
                //TODO:Se não tiver no estoque de ngm cai para a DECOFIT
                this.product.resale = this.revendedores;

                this.product.resale = "DECOFIT LTDA";
              } else {
                //TODO:inclui no carrinho o primeiro revendedor da lista dos que tem o sku
              }
              this.loading = false;
            })
            .catch((err) => {
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "error",
                title: "Nenhuma revenda possui este produto",
                message: "Nenhuma revenda possui este produto",
                trace: null,
              });
              this.loading = false;
            });
      }
    },

    selecionado(atributo) {
      return this.listSelectedAttributes.find(
          (x) =>
              x.hex === atributo.hex &&
              x.indexItem === atributo.indexItem &&
              x.name === atributo.name
      );
    },

    async selectIndexColor(itens, attr) {
      const grupoAtributosParemantro = attr;
      const atributosParemantro = itens;
      const payLoad = {
        indexItem: atributosParemantro.indexItem,
        name: atributosParemantro.name,
        hex: atributosParemantro.hex,
        attributeName: grupoAtributosParemantro.atributo.attribute.name,
        attributeType: grupoAtributosParemantro.atributo.attribute.type,
        indexAtributo: grupoAtributosParemantro.atributo.indexAtributo,
      };

      const atributeJaAdicionado = this.listSelectedAttributes.find(
          (x) =>
              x.indexAtributo === payLoad.indexAtributo &&
              x.indexItem === payLoad.indexItem
      );
      if (atributeJaAdicionado) {
        const indice = this.listSelectedAttributes.findIndex(
            (x) =>
                x.indexAtributo === payLoad.indexAtributo &&
                x.indexItem === payLoad.indexItem
        );
        this.listSelectedAttributes.splice(indice, 1);
      } else {
        const grupoAtributos = this.listSelectedAttributes.find(
            (x) => x.indexAtributo === payLoad.indexAtributo
        );
        if (grupoAtributos) {
          if (payLoad.indexAtributo === grupoAtributos.indexAtributo) {
            const indice = this.listSelectedAttributes.findIndex(
                (x) =>
                    x.indexAtributo === grupoAtributos.indexAtributo &&
                    x.indexItem === grupoAtributos.indexItem
            );
            this.listSelectedAttributes.splice(indice, 1, payLoad);
          }
        } else {
          this.listSelectedAttributes.push(payLoad);
        }
      }
      this.valor = 0;
      this.product.skuId = null;
      this.product.sku = null;
      if (this.listSelectedAttributes.length === this.attributes.length) {
        await this.selectSku();
      }
    },

    async selectFirstAttr() {
      if (this.attributes) {
        this.attributes.forEach((x) => {
          if (x.itensAtributos.length === 1) {
            this.listSelectedAttributes.push({
              indexItem: x.atributo.indexItem,
              name: x.atributo.name,
              hex: x.atributo.hex,
              attributeName: x.atributo.attribute.name,
              attributeType: x.atributo.attribute.type,
              indexAtributo: x.itensAtributos[0].indexAtributo,
            });
          }
        });
      }
      await this.selectSku();
    },

    async selectIndex(itens, attr) {
      const grupoAtributosParemantro = attr;
      const atributosParemantro = itens;
      const payLoad = {
        indexItem: atributosParemantro.indexItem,
        name: atributosParemantro.name,
        hex: atributosParemantro.hex,
        attributeName: grupoAtributosParemantro.atributo.attribute.name,
        attributeType: grupoAtributosParemantro.atributo.attribute.type,
        indexAtributo: grupoAtributosParemantro.atributo.indexAtributo,
      };

      const atributeJaAdicionado = this.listSelectedAttributes.find(
          (x) =>
              x.indexAtributo === payLoad.indexAtributo &&
              x.indexItem === payLoad.indexItem
      );
      if (atributeJaAdicionado) {
        const indice = this.listSelectedAttributes.findIndex(
            (x) =>
                x.indexAtributo === payLoad.indexAtributo &&
                x.indexItem === payLoad.indexItem
        );
        this.listSelectedAttributes.splice(indice, 1);
      } else {
        const grupoAtributos = this.listSelectedAttributes.find(
            (x) => x.indexAtributo === payLoad.indexAtributo
        );
        if (grupoAtributos) {
          if (payLoad.indexAtributo === grupoAtributos.indexAtributo) {
            const indice = this.listSelectedAttributes.findIndex(
                (x) =>
                    x.indexAtributo === grupoAtributos.indexAtributo &&
                    x.indexItem === grupoAtributos.indexItem
            );
            this.listSelectedAttributes.splice(indice, 1, payLoad);
          }
        } else {
          this.listSelectedAttributes.push(payLoad);
        }
      }
      this.valor = 0;
      this.product.skuId = null;
      this.product.sku = null;
      if (this.listSelectedAttributes.length === this.attributes.length) {
        await this.selectSku();
      }
    },

    async selectSku() {
      let skuSearch = null;
      const product = this.product;
      product.skus.forEach((sku) => {
        let skuTemTodosAtributosSelecionado = true;

        this.listSelectedAttributes.forEach((x) => {
          const sk = sku.attributes.find((y) => y.name === x.name);
          if (!sk) {
            skuTemTodosAtributosSelecionado = false;
          }
        });
        if (skuTemTodosAtributosSelecionado) {
          skuSearch = sku;
        }
      });
      if (skuSearch) {
        this.product.skuId = skuSearch.id;
        this.valor = skuSearch.differentValue;
        this.product.valor = this.valor;
        this.product.sku = skuSearch;
      } else {
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "warning",
          title: "Nenhuma revenda possui este produto",
          message: "Nenhuma revenda possui este produto",
          trace: null,
        });
      }
    },

    activateReadMore(arg) {
      switch (arg) {
        case 1:
          this.readMore1 = !this.readMore1;

        case 2:
          this.readMore2 = !this.readMore2;
      }
    },

    async searchPostal() {
      this.cepLoading = true;
      let client = new ApiService();

      await client
          .get(`user/postalcode?cep=${this.cep}`)
          .then((resp) => {
            const response = JSON.parse(resp);
            if (response.logradouro != null) {
              this.endereco =
                  response.logradouro +
                  ", " +
                  response.bairro +
                  ", " +
                  response.localidade;
              this.calcularFrete();
              this.isFrete = true;
              this.cepLoading = false;
            } else {
              this.isFrete = false;
              this.endereco = {};
              Vue.$globalEvent.$emit("onFlashMessage", {
                type: "error",
                title: "CEP não encontrado",
                message: "CEP não encontrado",
                trace: null,
              });

              this.cepLoading = false;
            }
          })
          .catch((erro) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Erro ao procurar CEP",
              message: err.body.message,
              trace: null,
            });

            this.isFrete = false;
            this.loading = false;
            this.cepLoading = false;
          });
    },

    async calcularFrete() {
      const dados = {
        CdServico: "04510",
        CepOrigem: this.cep,
        CepDestino: "04547000",
        VlPeso: "1",
        VlComprimento: "20",
        VlAltura: "2",
        VlLargura: "10",
        VlDiametro: "0",
      };
      const xml2js = require("xml2js");
      const util = require("util");
      const parser = new xml2js.Parser({
        explicityArray: false,
      });

      var client = new ApiService();
      await client
          .post(`client/frete`, dados)
          .then((response) => {
            parser.parseString(response, (err, result) => {
              this.frete = result.Servicos.cServico[0];
            });
          })
          .catch((erro) => {
            Vue.$globalEvent.$emit("onFlashMessage", {
              type: "error",
              title: "Não ao calcular o valor do frete",
              message: err.body.message,
              trace: null,
            });
          });
    },

    movimentarImagens(direction, px, event) {
      let fotos = document.getElementById("container-fotos");

      fotos.scrollWidth - fotos.clientWidth;
      fotos.scrollLeft += px * direction;
      event.preventDefault();
    },

    scrollBottom() {
      let target = document.querySelector(
          `#image${this.product.images.length - 1}`
      );
      let scrollContainer = document.querySelector(
          `#image${this.product.images.length - 1}`
      );
      do {
        //find scroll container
        scrollContainer = scrollContainer.parentNode;
        if (!scrollContainer) return;
        scrollContainer.scrollTop += 1;
      } while (scrollContainer.scrollTop == 0);

      var targetY = 0;
      do {
        //find the top of target relatively to the container
        if (target == scrollContainer) break;
        targetY += target.offsetTop;
      } while ((target = target.offsetParent));

      // start scrolling
      this.scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
    },

    scroll(c, a, b, i) {
      i++;
      if (i > 30) return;
      c.scrollTop = a + ((b - a) / 30) * i;
      setTimeout(function () {
        scroll(c, a, b, i);
      }, 20);
    },

    chooseSelectedImg(image) {
      this.product.productPic = image.path
    },

    setAttribues() {
      try {
        let attributesAux = [];
        let atributosUnicos = [];
        this.product.skus.filter((s) => s.isActive || s.resaleSkus.filter((res) => res.quantity > 0).length > 0)
            .forEach((x) => {
              x.attributes.forEach((atr) => {
                const atributoAdicionado = attributesAux.find(
                    (a) => a.attribute.name === atr.attribute.name
                );
                if (!atributoAdicionado) {
                  attributesAux.push(atr);
                }

                const itensAdicionados = atributosUnicos.find(
                    (b) => b.name === atr.name
                );
                if (!itensAdicionados) {
                  atributosUnicos.push(atr);
                }
              });
            });
        attributesAux.forEach((atributo) => {
          const itensAtributos = atributosUnicos.filter(
              (b) => b.attribute.name === atributo.attribute.name
          );
          this.attributes.push({
            atributo,
            itensAtributos,
          });
        });
        let indexAtributo = 1;
        let indexItem = 1;

        this.attributes.forEach((x) => {
          x.atributo.indexAtributo = indexAtributo++;
          x.itensAtributos.forEach((y) => {
            y.indexItem = indexItem++;
          });
        });
      } catch (e) {
      }
    },

    colorToFirst() {
      let findColor = this.attributes.filter((x) => x.type === "Cor");
      if (findColor.length > 0) {
        findColor.map((color) => {
          let idx = this.attributes.findIndex((y) => y == color);
          this.attributes.splice(0, 0, this.attributes.splice(idx, 1)[0]);
        });
      }
    },

    goToRoute(route) {
      if (this.$route.path != route) {
        this.$router.push(route);
      }
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-header {
  padding: 0 5px !important;
}

.container-reviews {
  width: 100% !important;

  .container-reviews-star {
    border-radius: 5px;
    border: 1px solid #DEDEDE;
    padding: 5px;
    width: 77px;
    height: 41px;

    .note {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: #6D7989;
    }
  }

  .container-reviews-evaluations {
    border-radius: 5px;
    border: 1px solid #DEDEDE;
    padding: 5px;
    width: 147px;
    height: 41px;
    cursor: pointer;

    .note {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: #6D7989;
    }
  }
}

.line {
  width: 100%;
  background: #DEDEDE;
  height: 1px;
}

.border-top {
  border-top: 1px solid #DEDEDE;
}
.border-bottom {
  border-bottom: 1px solid #DEDEDE;
}

.price-discount {
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #6D7989;
  text-decoration: line-through;
}

.price {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #bc0000;
}

.parcelamento {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #6D7989;
}

.attributeName {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #2D3038;
}

.container-quantity-purchase-add-cart {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.quantity {
  width: 32% !important;
  min-width: 110px;
}

.primary-input-b2c >>> input {
  text-align: center;
  border-radius: 6px;
}

.btn-purchase-container {
  width: 32% !important;

  .btn-purchase {
    min-width: 180px;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;

    &:hover {
      background-color: #24ce63 !important;
    }
  }
}

.btn-add-cart-container {
  width: 32% !important;

  .btn-add-cart {
    border-radius: 6px !important;
    background-color: #bc0000 !important;
    min-width: 180px;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;

    &:hover {
      opacity: .8;
    }
  }
}

.image-carousel {
  display: flex;
  flex-wrap: nowrap !important;
  overflow: hidden;
  scroll-behavior: smooth;
  z-index: 0;
  height: 90px;
  //width: 100%;
}

.image-carousel::-webkit-scrollbar {
  background-color: #fff;
  width: 9px;
}

.image-carousel::-webkit-scrollbar-thumb {
  background-color: rgba(114, 119, 131, 0.5);
  border-radius: 20px;
}

.btn-movimentar-img-left {
  margin-left: -10px;
  height: 90px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.btn-movimentar-img-right {
  margin-right: -10px;
  height: 90px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.btn-whatsapp {
  border-radius: 6px !important;
  background-color: #eefbef !important;
  border: 1px solid #1FAF38;

  span {
    color: #1FAF38;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }

  v-icon {
    color: #1FAF38 !important;
  }

  &:hover {
    background-color: #1FAF38 !important;
    border: 1px solid #1FAF38;

    span, v-icon {
      color: #FFFFFF !important;
    }
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel  {
  border-radius: 0 !important;
}

.calulator {
  border-radius: 5px !important;
  border: 1px solid #DEDEDE;

  .calulator-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #4F4F4F;
  }

  .calulator-text-red {
    color: #BC0000;
  }
}

.v-tab, .v-btn {
  text-transform: none !important;
}

.tab {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  color: #4F4F4F !important;
  /*text-transform: lowercase!important;*/
}

.active-tab {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #BC0000 !important;
}

.text-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #4F4F4F;
}

.paymentMethod-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #4F4F4F;
  margin-top: 1px;
}

.paymentMethod-sub-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #6D7989;
}

.brand {
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  padding: 2px;
}

.paymentMethod {
  border-radius: 6px;
}

.list-product-content {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));
}

.rating {
  cursor: pointer;
  color: #808080 !important;
  font-size: 16px;
}


.secondary-input {
  border-radius: 5px !important;
}

.textos {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #000000;
}

.linha {
  padding-bottom: 10px;
  border-bottom: 1px solid #5f6368;
}

.container-imagens {
  display: flex !important;
  width: 100% !important;
  flex-wrap: nowrap;
}

.image-carousel-btn {
  width: 145px;
  margin-right: 20px !important;
  height: 500px !important;
  margin-top: -10px !important;
  display: flex;
}

.img-principal {
  padding: 0px 10px;
}


.attributes {
  padding-right: 5px;
}

.attributes::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
  height: 8px;
}

.attributes::-webkit-scrollbar-thumb {
  background-color: rgba(114, 119, 131, 0.5);
}

.img {
  cursor: pointer;
  transition: 0.3s;
  margin-right: 6px !important;
  border-radius: 5px !important;
}

.img:hover {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2) !important;
}

.values-row {
  display: flex;
  padding-bottom: 3px;
}

.values-row::-webkit-scrollbar {
  background-color: #fff;
  height: 8px;
}

.values-row::-webkit-scrollbar-thumb {
  background-color: rgba(114, 119, 131, 0.5);
}

.btn-scroll {
  border-radius: 0% !important;
  cursor: pointer !important;
  transition: 0.2s !important;
  margin: 10px 0;
}

.btn-scroll:hover {
  transform: scale(1);
}

.btn-scroll-pressed {
  border: 2px solid #bc0000 !important;
  box-sizing: border-box !important;
  padding: 2px !important;
  background-clip: content-box;
}

.attr-item {
  text-align: center !important;
  cursor: pointer !important;
  padding: 12px !important;
  background: transparent !important;
  font-size: 14px !important;
  color: #2D3038 !important;
  font-weight: bold !important;
  border: 1.5px solid #DEDEDE;
}

.attr-item:hover {
  transform: scale(1.001);
}

.attr-item-pressed {
  background: #2D3038 !important;
  color: #FFFFFF !important;
}

.productName {
  font-weight: 700;
  font-size: 24px;
  color: #2D3038 !important;
}

.productCode {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #6D7989;
}

.label {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #000000;
}

.chips-details {
  background: #2d3038;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align-last: center;
  line-height: 21px;
  letter-spacing: 0.15px;

  color: #ffffff;
}

.v-label {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 21px !important;
  letter-spacing: 0.15px !important;

  color: #000000 !important;
}

.v-card__title {
  text-align-last: start !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 22px !important;
  line-height: 32px !important;
  letter-spacing: 0.13px !important;

  color: #000000 !important;
}

.v-card__subtitle {
  text-align-last: start;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 555 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.15px !important;

  color: #000000 !important;
}

.transparent-background {
  background-color: transparent;
  position: relative;
}

.calc-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1rem;
  letter-spacing: 0.15px;
  color: #bc0000;
}

.card-subprice {
  margin-top: 10px;
  margin-left: -10px;
}

.semSkuSelecionado {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #2D3038;
}

.link_compartilhar {
  width: 45px !important;
  height: 45px !important;
  border-radius: 50% !important;
  border: 1px solid #DEDEDE;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1350px) {
  .container-quantity-purchase-add-cart {
    flex-wrap: wrap !important;
  }

  .quantity {
    width: 50% !important;
  }

  .btn-purchase-container {
    width: 50% !important;
  }

  .btn-add-cart-container {
    width: 100% !important;

    .btn-add-cart {
      margin-top: -10px;

    }
  }
}

@media (max-width: 600px) {
  .select-type {
    width: 90% !important;
  }
}

@media (max-width: 600px) {
  .form {
    flex-direction: column;
  }
}
</style>
