<template>
  <v-container>
    <v-dialog
      max-width="700"
      scrollable
      v-model="paymentMethodsDialog"
    >
      <PaymentMethodsModal
        @close="paymentMethodsDialog = false"
       :total-amount-ordered="totalOrder()"
       :installments="parcelas"
      />
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="confirmOrder"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="#000000" dark>
            <h2 class="text-center mt-3">Confirmar pedido</h2>
          </v-toolbar>
          <v-card-text>
            <div class="text-h2 pa-10"></div>
          </v-card-text>
          <v-row
            cols="12"
            sm="12"
            md="12"
            lg="12"
            class="py-1 mx-0 fechar-pedido mt-5"
          >
            <v-col cols="12" sm="12" md="12" lg="12">
              <div class="d-flex justify-space-between align-content-start">
                <div class="totalOder">Total:</div>

                <div class="d-flex flex-column">
                  <span class="totalOder">{{
                    totalOrder().toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}</span>
                  <span
                    class="installments"
                    v-if="
                      currentClient.creditInstallments > 1 &&
                      paymentMethod === creditCard
                    "
                  >
                    em
                    {{
                      parcelas.find(
                        (x) => x.value === currentClient.creditInstallments
                      ).name
                    }}
                    de
                    {{
                      (
                        totalOrder() / currentClient.creditInstallments
                      ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </span>
                </div>
              </div>

              <v-btn
                :loading="loading"
                block
                class="secondary-button btn-fechar-pedido"
                @click="buy(), (dialog.value = false)"
              >
                Fechar pedido
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      v-if="!isMobile"
      v-model="selectAddressDialog"
      style="height: -webkit-fill-available; max-height: -webkit-fill-available"
      max-width="720"
    >
      <SelectAddress @close="selectAddressDialog = false" />
    </v-dialog>

    <v-dialog v-if="isMobile" v-model="selectAddressDialog">
      <SelectAddressMobile @close="selectAddressDialog = false" />
    </v-dialog>

    <v-dialog
      v-if="!isMobile"
      v-model="confirmPaymentDialog"
      style="height: -webkit-fill-available; max-height: -webkit-fill-available"
      max-width="480"
    >
      <ConfirmPayment
        title="Aguardando pagamento"
        message="Seu pedido de número 23123 foi recebido e está aguardando o pagamento. Acesse sua conta para acompnahar! Confira o resumo do pedido."
        buttonText="Acesse sua conta"
      />
    </v-dialog>

    <v-dialog
      v-if="!isMobile"
      v-model="deniedPaymentDialog"
      style="height: -webkit-fill-available; max-height: -webkit-fill-available"
      max-width="480"
    >
      <ConfirmPayment
        title="Pagamento recusado!"
        message="Seu pagamento foi recusado, acesse sua conta para alterar a forma de pagamento!"
        buttonText="Alterar forma de pagamento"
      />
    </v-dialog>

    <v-dialog
      v-if="!isMobile"
      v-model="approvePaymentDialog"
      style="height: -webkit-fill-available; max-height: -webkit-fill-available"
      max-width="480"
    >
      <ConfirmPayment
        title="Pagamento aprovado!"
        message="Seu pedido de número 23123 foi
      aprovado e está sendo preparado para o envio. Acesse sua conta para acompnahar!"
        buttonText="Acesse sua conta"
      />
    </v-dialog>

    <v-form
      action="javascript:void(0);"
      method="POST"
      data-pagarmecheckout-form
      ref="firstForm"
    >
      <v-row class="ma-0 flex-column-reverse flex-sm-row">
        <v-col cols="12" sm="8">
          <v-row class="ma-0" no-gutters>
            <v-col>
              <v-card class="itens-card">
                <div class="d-flex align-center">
                  <v-icon color="normalred" size="33" class="mr-2">
                    mdi-truck-delivery-outline
                  </v-icon>
                  <strong class="delivery-text">Endereço de entrega</strong>
                </div>

                <v-card class="pa-4" outlined style="border-radius: 10px" flat>
                  <strong>Casa</strong>
                  <p class="mb-0">
                    CEP: {{ endereco.postalCode }} | {{ endereco.addressName }},
                    {{
                      endereco.addessNumber
                        ? "n°" + endereco.addessNumber + ","
                        : ""
                    }}
                    {{ endereco.neighborhood }} - {{ endereco.city }} -
                    {{ endereco.state }}
                  </p>
                </v-card>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="itens-card">
                <div class="d-flex align-center mb-4">
                  <v-icon color="normalred" size="33" class="mr-2">
                    mdi-credit-card-multiple-outline
                  </v-icon>
                  <strong class="delivery-text">Formas de pagamento</strong>
                </div>

                <p
                  class="mb-0"
                  style="
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 32px;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Escolha uma forma de pagamento
                </p>

                <v-row class="ma-0">
                  <v-col>
                    <v-card
                      class="pa-3 mt-3 d-flex flex-column"
                      :class="paymentMethod == 0 ? 'active-card' : ''"
                      outlined
                      style="border-radius: 10px"
                      flat
                      @click="paymentMethod = 0"
                    >
                      <div class="d-flex align-center">
                        <v-btn small icon>
                          <v-icon
                            :color="paymentMethod == 0 ? 'normalred' : ''"
                          >
                            {{
                              paymentMethod == 0
                                ? "mdi-circle-slice-8"
                                : "mdi-checkbox-blank-circle-outline"
                            }}
                          </v-icon>
                        </v-btn>
                        <v-icon
                          class="mr-2"
                          size="20"
                          :color="paymentMethod == 0 ? 'normalred' : ''"
                        >
                          mdi-credit-card-outline
                        </v-icon>
                        <strong
                          :style="paymentMethod == 0 ? 'color: #BC0000' : ''"
                        >
                          Cartão de crédito
                        </strong>
                      </div>

                      <div class="d-flex align-center">
                        <v-img
                          class="mr-2"
                          src="../../../../assets/payments.svg"
                          max-width="160"
                          contain
                        ></v-img>
                        <span>e mais...</span>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      class="pa-3 mt-3 d-flex flex-column"
                      outlined
                      :class="paymentMethod == 2 ? 'active-card' : ''"
                      style="border-radius: 10px"
                      flat
                      @click="paymentMethod = 2"
                    >
                      <div class="d-flex align-center">
                        <v-btn
                          small
                          icon
                          :color="paymentMethod == 2 ? 'normalred' : ''"
                        >
                          <v-icon>
                            {{
                              paymentMethod == 2
                                ? "mdi-circle-slice-8"
                                : "mdi-checkbox-blank-circle-outline"
                            }}
                          </v-icon>
                        </v-btn>
                        <div>
                          <v-img
                            :src="
                              paymentMethod == 2
                                ? require('../../../../assets/pix-red.svg')
                                : require('../../../../assets/pix.svg')
                            "
                            max-height="20"
                            contain
                          ></v-img>
                        </div>
                        <strong
                          :style="paymentMethod == 2 ? 'color: #BC0000' : ''"
                        >
                          Pix
                        </strong>
                      </div>

                      <div class="d-flex align-center">
                        <span> Aprovação imediata do pagamento </span>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      class="pa-3 mt-3 d-flex flex-column"
                      :class="paymentMethod == 1 ? 'active-card' : ''"
                      outlined
                      style="border-radius: 10px"
                      flat
                      @click="paymentMethod = 1"
                    >
                      <div class="d-flex align-center">
                        <v-btn
                          small
                          icon
                          :color="paymentMethod == 1 ? 'normalred' : ''"
                        >
                          <v-icon>
                            {{
                              paymentMethod == 1
                                ? "mdi-circle-slice-8"
                                : "mdi-checkbox-blank-circle-outline"
                            }}
                          </v-icon>
                        </v-btn>
                        <div>
                          <v-img
                            :src="
                              paymentMethod == 1
                                ? require('../../../../assets/boleto-red.svg')
                                : require('../../../../assets/boleto-icon.svg')
                            "
                            max-height="20"
                            contain
                          ></v-img>
                        </div>
                        <strong
                          :style="paymentMethod == 1 ? 'color: #BC0000' : ''"
                        >
                          Boleto Bancário
                        </strong>
                      </div>

                      <div class="d-flex align-center">
                        <span>
                          2 a 3 dias úteis para compensação do pagamento.
                        </span>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>

                <v-expand-transition>
                  <div v-if="paymentMethod == 0">
                    <v-divider class="my-3"></v-divider>

                    <v-row :style="i > 0 ? 'display: none': ''" v-for="(item, i) in calculoFrete" class="ma-0" :key="i">
                      <v-col cols="12">
                        <strong>Número do cartão</strong>
                        <v-text-field
                            color="normalred"
                            class="elevation0 elevation-0"
                            name="card-number"
                            :data-pagarmecheckout-element="`number-${i}`"
                            background-color="#F2F2F2"
                            dense
                            hide-details="auto"
                            filled
                            rounded
                            style="border-radius: 6px !important"
                            v-mask="['#### #### #### ####']"
                            placeholder="0000.0000.0000.000"
                            v-model="card.card_number"
                            required
                            :rules="[rules.required]"
                        />
                      </v-col>

                      <v-col cols="12" sm="4">
                        <strong>Validade (mês/ano)</strong>
                        <v-text-field
                            color="normalred"
                            name="card-exp-date"
                            data-pagarmecheckout-separator="-"
                            :data-pagarmecheckout-element="`exp_date-${i}`"
                            dense
                            hide-details="auto"
                            placeholder="MM-AA"
                            v-mask="['##-##']"
                            v-model="card.card_expiration_date"
                            required
                            :rules="[rules.required]"
                            filled
                            rounded
                            style="border-radius: 6px !important"
                        />
                      </v-col>

                      <v-col cols="12" sm="4">
                        <strong>Código de Segurança (CVV)</strong>
                        <v-text-field
                            color="normalred"
                            name="cvv"
                            :data-pagarmecheckout-element="`cvv-${i}`"
                            dense
                            hide-details="auto"
                            placeholder="000"
                            v-mask="['####']"
                            v-model="card.card_cvv"
                            :rules="[rules.required]"
                            required
                            filled
                            rounded
                            style="border-radius: 6px !important"
                        />
                      </v-col>

                      <v-col cols="12">
                        <strong>Nome Completo (conforme impresso no cartão)</strong>
                        <v-text-field
                            color="normalred"
                            name="holder-name"
                            :data-pagarmecheckout-element="`holder_name-${i}`"
                            dense
                            hide-details="auto"
                            placeholder="Nome e sobrenome"
                            v-model="card.card_holder_name"
                            required
                            :rules="[rules.required]"
                            filled
                            rounded
                            style="border-radius: 6px !important"
                        />
                      </v-col>
                    </v-row>

                    <v-row class="ma-0" cols="12">
                      <v-col cols="7">
                      <strong>Número de Parcelas</strong>
                      <v-select
                          color="normalred"
                          dense
                          placeholder="Selecione o número de parcelas"
                          :items="parcelas"
                          hint="Preencha os dados do cartão para alterar o número de parcelas"
                          persistent-hint
                          item-text="name"
                          item-value="value"
                          append-icon="mdi-chevron-down"
                          v-model="currentClient.creditInstallments"
                          required
                          :rules="[rules.required]"
                          filled
                          rounded
                          style="
                            border-radius: 6px !important;
                            text-transform: none !important;
                          "
                      />
                    </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card class="itens-card">
            <div class="d-flex align-center">
              <v-icon size="33" color="normalred" class="mr-2">
                mdi-file-document-multiple-outline
              </v-icon>
              <strong class="delivery-text">Resumo do pedido</strong>
            </div>

            <div class="itens">
              <div class="d-flex my-2" v-for="item in order" :key="item.skuId">
                <v-img
                  class="mr-2"
                  :src="env + item.image"
                  max-width="90"
                  contain
                ></v-img>

                <div>
                  <span>
                    <strong v-if="item.type === 'produto'">
                      {{ item.qty }}X
                    </strong>
                    {{ item.name }}
                  </span>

                  <br />
                  <br />

                  <div class="d-flex justify-space-between align-end">
                    <div>
                      Variação:
                      <br />
                      {{ item.attribute }}
                    </div>
                    <h3>
                      {{
                        (item.valor * item.qty).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <v-divider class="ma-3 my-5"></v-divider>

            <div>
              <strong class="item-text">
                {{ !coupon ? "Possui cupom?" : "Cupom aplicado" }}
              </strong>
              <div class="d-flex">
                <v-text-field
                  color="normalred"
                  @input="
                    currentOrder.coupon = currentOrder.coupon.toUpperCase()
                  "
                  v-model="currentOrder.coupon"
                  v-if="!coupon"
                  :error-messages="couponMessage"
                  class="mr-2"
                  background-color="#F2F2F2"
                  dense
                  filled
                  rounded
                  style="border-radius: 6px !important"
                ></v-text-field>
                <div
                  v-else
                  style="
                    background: #f2f2f2;
                    border-radius: 6px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #bc0000;
                    width: 100%;
                    text-align: center;
                  "
                  class="pa-4 mr-2 my-2"
                >
                  {{ coupon.code }}
                </div>
                <v-btn
                  outlined
                  color="normalred"
                  v-if="!coupon"
                  @click="getCoupon"
                >
                  <strong> Aplicar </strong>
                </v-btn>
                <v-btn
                  v-else
                  @click="removeCupon"
                  fab
                  class="mt-4"
                  depressed
                  x-small
                  color="normalred"
                >
                  <v-icon small> mdi-close </v-icon>
                </v-btn>
              </div>
            </div>

            <div
              class="mt-3 pa-3"
              style="background: #f2f2f2; border-radius: 3px"
            >
              <div class="d-flex">
                <strong class="item-text">Produtos</strong>
                <v-spacer></v-spacer>
                <strong class="item-text">
                  {{
                    (total - valorFrete).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </strong>
              </div>

              <br />

              <div class="d-flex">
                <strong class="item-text">Frete </strong>
                <v-spacer></v-spacer>
                <strong class="item-text">
                  {{
                    valorFrete.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </strong>
              </div>

              <br v-if="discount || productPercentageDiscount" />

              <div class="d-flex" v-if="discount || productPercentageDiscount">
                <strong class="item-text">Desconto </strong>
                <v-spacer></v-spacer>
                <strong class="item-text" style="color: #008000">
                  -{{
                    (discount + productPercentageDiscount).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </strong>
              </div>

              <br />

              <hr class="mx-2" style="background-color: #d9d9d9" />

              <br />

              <div class="d-flex">
                <strong
                  class="item-text"
                  style="color: #bc0000 !important; font-weight: 700"
                >
                  Total
                </strong>
                <v-spacer></v-spacer>
                <strong
                  class="item-text"
                  style="color: #bc0000 !important; font-weight: 700"
                >
                  {{
                    totalOrder().toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </strong>
              </div>

              <div class="d-flex justify-end">
                <strong
                    style="font-size: 12px" class="item-text mt-2"
                    v-if="currentClient.creditInstallments > 1 && paymentMethod === creditCard"
                >
                      {{ parcelas.find((x) => x.value === currentClient.creditInstallments).name }} de {{(totalOrder() / currentClient.creditInstallments ).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </strong>
              </div>

              <div class="mt-2">
                <a class="formas-pagamento" @click="paymentMethodsDialog = true">
                  Mais formas de pagamento
                </a>
              </div>
            </div>

            <v-btn
              block
              type="submit"
              color="normalred"
              class="mt-5"
              depressed
              :loading="loading"
              @click="validateFields"
            >
              <div class="d-flex align-center" style="text-transform: none">
                <v-icon class="mr-2"> mdi-shield-check </v-icon>
                <strong>Finalizar compra</strong>
              </div>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<style scoped>
.active-card {
  border: 1px solid #bc0000 !important;
  box-shadow: 0px 12px 20px rgba(14, 31, 53, 0.12) !important;
}
.item-text {
  font-weight: 500;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.7);
}

.itens {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 5px;
}

.itens::-webkit-scrollbar {
  width: 7px;
  background: #d9d9d9;
  border-radius: 4px;
}

.itens::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 4px;
}
.delivery-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #2d3038;
}

.itens-card {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding: 24px;
}
</style>

<style scoped>
.formas-pagamento{
  color: #000000;
  text-decoration: underline;
}
.totalOder {
  font-weight: bold;
  text-align: right;
}
.installments {
  font-size: 12px;
  font-weight: bold;
}
.forma-pagamento-row {
  text-align: left;
  margin: 0 0 0 10px !important;
}
.fechar-pedido {
  text-align: center;
  font-size: x-large;
  margin-top: -20px !important;
  padding-bottom: 20px !important;
}
.btn-fechar-pedido {
  height: 41px !important;
  border-radius: 5px !important;
}
.desconto-frete {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #000000;
}

.price {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.15px;

  color: #000000;
}

.qty-product {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #000000;
}

.qty-name {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #000000;
}

.parcelas {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #000000;
}

.total-pedido {
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid #000000;
  padding: 10px 0;
}

.itens-pedido {
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.desconto-pedido {
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.frete-pedido {
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}

.total {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #000000;
}

.sombra {
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
}

.sucess-coupon {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.15px;

  color: #8dc63f;
}

.error-coupon {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #ec1313;
}

.coupon-button {
  width: 50% !important;
  height: 50px;
  background: rgba(196, 196, 196, 0.5);
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 4px;
}

.coupon-button:hover {
  background: #bc0000 !important;

  color: white !important;
}

.span-details {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #2d3038;
}

.span-details:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.span-order {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.cabeca {
  border-start-end-radius: inherit;
  border-start-start-radius: inherit;
  display: flex;
  justify-content: space-between;
}
.checkbox-mobile {
  margin-top: -10px !important;
  margin-bottom: 10px !important;
}

.no-pointer {
  cursor: auto !important;
}

.label {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #c4c4c4;
}
.pad-mobile {
  padding: 10px !important;
}

.pad-all {
  padding: 0px 45px 45px 45px !important;
}

.radio-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  margin-left: -10px !important;
  color: #000000;
}
.v-input--radio-group {
  flex-direction: row !important;
}

.cards {
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1) !important;
  border-radius: 8px !important;
  height: 300px !important;
}

.voltar-btn {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #000000;
}

.voltar-btn:hover {
  cursor: pointer;
}
.label-right {
  cursor: pointer;
  color: #000000;
  justify-content: space-between !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
}
.radio {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  width: 230px !important;
  min-height: 80px !important;
  margin-left: -10px !important;
  padding-left: 10px !important;
}

.span-title {
  padding-top: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  color: rgba(0, 0, 0, 0.5);
}

.mainCheckout {
  padding-top: 5px !important;
  max-width: unset !important;
  text-align: -webkit-center;
  background-color: #f5f5f5;
}

#mainCheckout {
  font-family: Roboto, serif !important;
}

@media (max-width: 1080px) {
  .radio {
    width: 105% !important;
    height: 65px !important;
  }
}

@media (max-width: 960px) and (min-width: 768px) {
  /*.total-pedido {
    padding: 0 40px !important;
  }*/
}

@media (max-width: 660px) {
  .pad-all {
    padding: 0px 0px 0px 0px !important;
  }

  .endereco {
    border-radius: 6px;
    width: 130px !important;
    height: 70px !important;
  }
}

.hidden-mobile {
  display: none;
}

@media (max-width: 960px) {
  .hidden-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .margin-top {
    margin-top: -22px !important;
  }
  .forma-pagamento-row {
    width: 95% !important;
  }
}
</style>

<script>
import ApiService from "../../../../services/ApiService";
import SelectAddress from "../../components/SelectAddress.vue";
import PaymentMethodsModal from "../../components/PaymentMethodsModal.vue";
import SelectAddressMobile from "../../components/SelectAddressMobile.vue";
import ConfirmPayment from "../../components/ConfirmPayment.vue";
import Vue from "vue";
import axios from "axios";
import Utils from "../../../../Utils/ValidateCPFCNPJ";
import moment from "moment";

export default {
  name: "Checkout",

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  components: {
    SelectAddress,
    SelectAddressMobile,
    ConfirmPayment,
    PaymentMethodsModal,
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  data: () => ({
    coupon: null,
    couponMessage: "",
    active: false,
    typeUserInstaller: 3,
    typeUserClient: 4,
    resalePicker: [],
    currentOrder: [],
    success1: false,
    salvarDadodCartao: false,
    erroinvalid: false,
    errodesativado: false,
    clear: false,
    thirdValid: true,
    msgErro: "",
    msgSucess: "",
    dropdown: true,
    componentKey: 1,
    pagarmetokens: {},
    total: 0,
    prazoFrete: 0,
    calculoFrete: [],
    fretePadrao: {},
    valorFrete: 0,
    cardId: "",
    orderCode: "",
    deliveryMethod: 0,
    paymentMethod: 0,
    endereco: [],
    discount: 0,
    productPercentageDiscount: 0,
    frete: {},
    order: [],
    percentual: 1,
    taxaJuros: 5,
    bankSlip: 1,
    creditCard: 0,
    paymentMethodsDialog: false,
    parcelas: [
      { name: "Em 1x sem juros", value: 1, totalAmountOrdered: 0 },
      { name: "Em 2x sem juros", value: 2, totalAmountOrdered: 0 },
      { name: "Em 3x sem juros", value: 3, totalAmountOrdered: 0 },
      { name: "Em 4x com juros", value: 4, totalAmountOrdered: 0 },
      { name: "Em 5x com juros", value: 5, totalAmountOrdered: 0 },
    ],
    apiService: new ApiService(),
    isMobile: false,
    src: "",
    isBoleto: false,
    loading: false,
    orderCanBeWithdrawn: false,
    deniedPaymentDialog: false,
    confirmOrder: false,
    approvePaymentDialog: false,
    confirmPaymentDialog: false,
    selectAddressDialog: false,
    currentClient: {
      phone: "",
      creditInstallments: 1,
    },
    phone: "",
    document: "",
    card: {
      card_holder_name: "",
      card_number: "",
      card_expiration_date: "",
      card_cvv: "",
    },
    couponTypeCategory: 3,
    couponTypetypeSubcategory: 4,
    couponTypetypeProduct: 5,
    benefitPercentual: 1,
    benefitReais: 2,
    radioGroup: 0,
    radioGroup2: "",
    env: process.env.VUE_APP_BASE_URL,
    rules: {
      min: (value) => value.length >= 14 || "Telefone invalido.",
      required: (value) => !!value || "Campo requerido.",
      validateCPF: (value) =>
        Utils.CPF(value) ||
        value.toString().replace(/\D/g, "").length > 11 ||
        "Cpf Inválido",
      validateCNPJ: (value) =>
        Utils.CNPJ(value) ||
        value.toString().replace(/\D/g, "").length <= 11 ||
        "Cnpf Inválido",
    },
    percentageDiscountForApplicators: 0,
  }),

  watch: {},

  computed: {
    dateBuy() {
      return moment().add(14, "days").format("DD/MM/YYYY");
    },
  },

  async created() {
    this.env = process.env.VUE_APP_BASE_URL;
    this.user = JSON.parse(localStorage.getItem("user"));

    if (this.user) {
      await this.getCart();
      await this.getFreightQuote();
    } else {
      this.cart = JSON.parse(localStorage.getItem("cart"));
    }

    const card = JSON.parse(localStorage.getItem("card"));

    if (card) {
      this.card = card;
    }

    this.currentUser = this.user;

    this.cart.items.forEach((x) => {
      let discont = 0;
      if (x.sku.product.percentage > 0) {
        discont = (x.sku.product.percentage / 100) * x.sku.differentValue
      } else if (x.promotion) {
        discont = x.promotion.discountType === this.percentual ? (x.promotion.discount / 100) * x.sku.differentValue : x.promotion.discount
      }

      this.productPercentageDiscount += discont * x.quantity;

      this.order.push({
        attribute: x.sku.attributes[0].name,
        image: x.sku.product.productPic,
        subcategoryIds: x.sku.product.subCategories.map((y) => y.subCategoryId),
        categoryIds: x.sku.product.categories.map((y) => y.categoryId),
        productId: x.sku.productId,
        name: x.sku.product.productName,
        qty: x.quantity,
        valor: x.sku.differentValue - discont,
        type: "produto",
      });
    });
    this.deliveryAddress = JSON.parse(localStorage.getItem("deliveryAddress"));
    this.endereco = this.deliveryAddress;
    await this.calcularFrete();
    this.calculateTotal()
    await this.applyApplicatorDiscount();
  },

  methods: {
    updateInstallmentValue(total){
      total -= Number(this.discount)
      this.parcelas.forEach((parcela) => {
        if(parcela.value >= 4){
          parcela.totalAmountOrdered = Number(total + this.calculateTotalInterestPerProduct(parcela.value))
          const valorParcela = Number(((total + this.calculateTotalInterestPerProduct(parcela.value)) / parcela.value).toFixed(2))
          parcela.name = `Em ${parcela.value}x com juros de ${this.taxaJuros}% A.M por ${valorParcela.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}`
        } else {
          parcela.totalAmountOrdered = Number(total)
          const valorParcela = Number((total / parcela.value).toFixed(2))
          parcela.name = `Em ${parcela.value}x sem juros por ${valorParcela.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}`
        }
      })
    },

    async getCart() {
      await this.apiService.get("cart").then((response) => {
        this.cart = response.content;
      });
    },

    async getFreightQuote() {
      await this.apiService
          .get(`freightQuote`, false, false, false)
          .then((response) => {
            const { content } = response
            if(content && content.quote){
              this.calculoFrete = JSON.parse(content.quote)
            } else{
              this.goToRoute("/cart");
            }
        }).catch(error => {

        })
    },

    async removeFreightQuote() {
      await this.apiService
          .delete(`freightQuote`)
          .then(async (response) => {
            this.calculoFrete = []
          }).catch((error) => {

          })
    },

    async applyApplicatorDiscount() {
      if (this.coupon) {
        this.discount = 0
        this.calculateTotal()
        return
      }
      if (this.user && this.user.type === this.typeUserInstaller) {
        const discount = await this.apiService.get("platformsettings/get");
        if (
          discount &&
          discount.percentageDiscountForApplicators &&
          discount.percentageDiscountForApplicators > 0
        ) {
          let total = this.total - parseFloat(this.valorFrete);
          this.percentageDiscountForApplicators =
            discount.percentageDiscountForApplicators;
          const porcentagem = total / 100;
          this.discount = porcentagem * this.percentageDiscountForApplicators;
          total -= this.discount;
          this.total = total + parseFloat(this.valorFrete);
        }
      }
    },

    async getLocation() {
      let address = [
        this.endereco.addressNumber,
        this.endereco.addressName,
        this.endereco.state,
        this.endereco.city,
      ].join(" ");

      await axios
        .get("https://maps.googleapis.com/maps/api/geocode/json", {
          params: {
            address: address,
            key: process.env.VUE_APP_APIKEY,
          },
        })
        .then((response) => {
          let result = response.data.results[0].geometry.location;

          this.location = {
            latitude: parseFloat(result.lat),
            longitude: parseFloat(result.lng),
            place_id: response.data.results[0].place_id,
          };
          this.endereco.latitude = this.location.latitude;
          this.endereco.longitude = this.location.longitude;
        })
        .catch((erro) => {});
    },

    async removeCupon() {
      this.coupon = null;
      this.discount = 0;
      this.currentOrder.coupon = null;
      this.msgSucess = "";
      this.msgErro = "";
      this.calculateTotal()
      await this.applyApplicatorDiscount();
      this.totalOrder();
    },

    async getCoupon() {
      const model = {
        productIds: this.order.map((x) => x.productId),
        code: this.currentOrder.coupon,
        userId: this.user.id,
      };

      await this.apiService
        .post(`coupon/get-oneCode`, model)
        .then((resp) => {
          this.totalOrder();

          this.msgSucess = null;
          this.msgErro = null;

          const message = resp.message;
          this.coupon = resp.content;

          if (this.coupon == null) {
            this.couponMessage = "O cupom inserido não existe.";

            this.coupon = null;
            this.discount = 0;
            return;
          }

          if (
            this.coupon &&
            this.coupon.minimum_price > 0 &&
            this.total < this.coupon.minimum_price
          ) {
            this.couponMessage = `Para usar esse cupom o valor de pedido tem que ser maior que ${this.coupon.minimum_price.toLocaleString(
              "pt-br",
              {
                style: "currency",
                currency: "BRL",
              }
            )}`;
            this.coupon = null;
            this.msgErro = "Cupom inválido ";
            return;
          }

          if (this.coupon && this.total < this.coupon.descontoReais) {
            this.couponMessage = `Para usar esse cupom o valor de pedido tem que ser maior que o valor do desconto`;
            this.msgErro = "Cupom inválido ";
            this.coupon = null;
            return;
          }

          if (
            this.coupon &&
            this.coupon.minimum_price > 0 &&
            this.total < this.coupon.minimum_price
          ) {
            this.couponMessage = `Para usar esse cupom o valor de pedido tem que ser maior que ${this.coupon.minimum_price.toLocaleString(
              "pt-br",
              {
                style: "currency",
                currency: "BRL",
              }
            )}`;
            this.coupon = null;
            this.msgErro = "Cupom inválido ";
            return;
          }

          this.calculateDiscount();
          this.totalOrder()

          this.msgSucess = this.coupon.descontoPercentual
            ? "Cupom de " +
              this.coupon.descontoPercentual +
              "% aplicado com sucesso"
            : "Cupom de " +
              this.coupon.descontoReais.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }) +
              " aplicado com sucesso";

          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "success",
            title: "Cupom",
            message: message,
            trace: null,
          });

          this.loading = false;
          setTimeout(() => {
            this.couponMessage = "";
          }, 10000);
        })
        .catch((err) => {
          this.couponMessage = "O cupom inserido não existe.";

          setTimeout(() => {
            this.couponMessage = "";
          }, 10000);
          this.loading = false;
        });
    },

    calculateTotal() {
      let total = 0;
      this.order.forEach((x) => {
        total += x.valor * x.qty;
      });
      total = total + parseFloat(this.valorFrete);
      this.total = total;
      this.componentKey += 1;
    },

    totalOrder() {
      let total = 0;
      this.order.forEach((x) => {
        total += x.valor * x.qty;
      });
      total += parseFloat(this.valorFrete);

      if (this.paymentMethod === this.bankSlip) {
        return (total -= Number(this.discount));
      }
      this.updateInstallmentValue(total);

      if (this.currentClient.creditInstallments <= 3) {
        total -= Number(this.discount);
      } else {
        total += this.calculateTotalInterestPerProduct()
        total -= Number(this.discount)
      }
      return total;
    },

    async calculateDiscount() {
      await this.applyApplicatorDiscount();
      this.percentageDiscountForApplicators = 0;

      if (this.coupon.benefit === 2) {
        let total = this.total - parseFloat(this.valorFrete);
        this.discount = parseFloat(this.coupon.descontoReais);
        total -= parseFloat(this.coupon.descontoReais);
        this.total = total + parseFloat(this.valorFrete);
      } else {
        if (this.coupon.type == 3) {
          let total = 0;
          this.order.forEach((x) => {
            let value = x.valor;
            if (x.categoryIds.includes(this.coupon.categoryId)) {
              value -= (x.valor / 100) * this.coupon.descontoPercentual;
            }
            total += value * x.qty;
          });
          const precoTotal = this.order.reduce(
            (total, produto) => total + produto.valor * produto.qty,
            0
          );
          this.discount = parseFloat(precoTotal) - total;
          total = total + parseFloat(this.valorFrete);
          this.total = total;
          this.componentKey += 1;
          return;
        }

        if (this.coupon.type == 4) {
          let total = 0;
          this.order.forEach((x) => {
            let value = x.valor;
            if (x.subcategoryIds.includes(this.coupon.subCategoryId)) {
              value -= (x.valor / 100) * this.coupon.descontoPercentual;
            }
            total += value * x.qty;
          });
          const precoTotal = this.order.reduce(
            (total, produto) => total + produto.valor * produto.qty,
            0
          );
          this.discount = parseFloat(precoTotal) - total;
          total = total + parseFloat(this.valorFrete);
          this.total = total;
          this.componentKey += 1;
          return;
        }

        if (this.coupon.type == 5) {
          let total = 0;
          this.order.forEach((x) => {
            let value = x.valor;
            if (x.productId == this.coupon.productId) {
              value -= (x.valor / 100) * this.coupon.descontoPercentual;
            }
            total += value * x.qty;
          });
          const precoTotal = this.order.reduce(
            (total, produto) => total + produto.valor * produto.qty,
            0
          );
          this.discount = parseFloat(precoTotal) - total;
          total = total + parseFloat(this.valorFrete);
          this.total = total;
          this.componentKey += 1;
          return;
        }

        let total = this.total - parseFloat(this.valorFrete);
        const porcentagem = total / 100;
        this.discount = porcentagem * this.coupon.descontoPercentual;
        total -= this.discount;
        this.total = total + parseFloat(this.valorFrete);
      }
    },

    pedidoCode() {
      const code = moment().format("YYYYMMDD") + "-" + Math.random();
      this.orderCode = code.toString().replace(".", "");
      return this.orderCode;
    },

    validateFields() {
      this.loading = true;
      if (!this.$refs.firstForm.validate()) {
        this.loading = false;
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Erro",
          message: "Preencha todos os campos!",
          trace: null,
        });
        return;
      }
      if (this.paymentMethod === null || this.deliveryMethod === null) {
        this.loading = false;
        Vue.$globalEvent.$emit("onFlashMessage", {
          type: "error",
          title: "Erro",
          message: "Selecione a forma de recebimento e uma forma de pagamento.",
          trace: null,
        });
        return;
      }

      if (this.paymentMethod === this.creditCard) {
        PagarmeCheckout.init(this.success, this.fail);
      } else {
        this.cardId = "";
        this.buy();
      }
    },

    async buy() {
      this.loading = true;
      const orders = [];
      for (let i = 0; i < this.calculoFrete.length; i++) {
        this.orderCode = this.pedidoCode();

        const model = {
          CouponId: this.coupon ? this.coupon.id : 0,
          OrderCode: this.orderCode,
          DeliveryId: this.deliveryAddress.id,
          ClientId: this.deliveryAddress.clientId,
          OrderStatus: 0,
          PrazoEntrega: this.calculoFrete[i].melhorEnvio ? this.calculoFrete[i].selectedShipping.delivery_range.max : 10,
          PaymentMethod: this.paymentMethod,
          DeliveryMethod: this.deliveryMethod,
          Phone: this.currentClient.phone,
          FretePrice: this.calculoFrete[i].melhorEnvio ? Number((this.calculoFrete[i].selectedShipping.price).toFixed(2)) : Number(this.calculoFrete[i].price.toFixed(2)),
          CreditInstallments: this.currentClient.creditInstallments,
          Address: this.deliveryAddress,
          Document: this.document,
          Items: [],
          CardId: this.paymentMethod === this.creditCard ? this.pagarmetokens[`pagarmetoken-${i}`] : null,
          AnswerShippingCalculationModel: this.calculoFrete[i].melhorEnvio ? this.calculoFrete[i].selectedShipping : null,
          ResaleId: this.calculoFrete[i].resaleId,
          Platform: navigator.platform,
        };
        let productPercentageDiscount = 0
        this.calculoFrete[i].productsSku.forEach((x) => {
          let percentage = 0;
          const [promotion] = x.product.promotions
          if (x.product.promotionStart && x.product.promotionEnd) {
            if (moment().isAfter(moment(x.product.promotionStart)) && moment().isBefore(moment(x.product.promotionEnd))) {
              percentage = x.product.percentage;
            }
          } else if (promotion) {
            percentage = promotion.promotion.discountType === this.percentual ? promotion.promotion.discount  : (promotion.promotion.discount / x.differentValue) * 100
          }
          productPercentageDiscount += (percentage / 100) * (x.differentValue * x.quantity);
          model.Items.push({
            ProductId: x.productId,
            SkuId: x.id,
            Price: (Number(x.differentValue) - (percentage / 100) * Number(x.differentValue).toFixed(2)),
            Qty: x.quantity,
            productsSku: x
          });
        });

        let discountTotal = 0

        if(this.percentageDiscountForApplicators > 0){
          model.Items.forEach((item) => {
            const discount = (item.Price / 100) * Number(this.percentageDiscountForApplicators);
            discountTotal += discount * item.Qty;
          })
        }

        if (this.coupon && this.coupon.benefit) {
          discountTotal = 0
          model.Items.forEach((item) => {
            const subCategories =
                item.productsSku.product && item.productsSku.product.subCategories && item.productsSku.product.subCategories.length > 0 ?
                    item.productsSku.product.subCategories && item.productsSku.product.subCategories.map((x) => x.subCategoryId) : []

            const categories =
                item.productsSku.product && item.productsSku.product.categories && item.productsSku.product.categories.length > 0 ?
                    item.productsSku.product.categories && item.productsSku.product.categories.map((x) => x.categoryId) : []

            let appliedIn = null
            if (this.coupon.benefit === this.benefitPercentual){
              if (this.coupon.type === this.couponTypeCategory) {
                appliedIn = this.couponTypeCategory
                if (this.coupon.categoryId && categories.includes(this.coupon.categoryId)) {
                  const discount = (item.Price / 100) * Number(this.coupon.descontoPercentual);
                  discountTotal += discount * item.Qty;
                }
              } else if(this.coupon.type === this.couponTypetypeSubcategory){
                appliedIn = this.couponTypetypeSubcategory
                if (this.coupon.subCategoryId && subCategories.includes(this.coupon.subCategoryId)) {
                  const discount = (item.Price / 100) * Number(this.coupon.descontoPercentual);
                  discountTotal += discount * item.Qty;
                }
              } else if(this.coupon.type === this.couponTypetypeProduct){
                appliedIn = this.couponTypetypeProduct
                if (this.coupon.productId && this.coupon.productId === item.product.productId) {
                  const discount = (item.Price / 100) * Number(this.coupon.descontoPercentual);
                  discountTotal += discount * item.Qty;
                }
              }
              if (!appliedIn) {
                const discount = (item.Price / 100) * Number(this.coupon.descontoPercentual);
                discountTotal += discount * item.Qty;
              }
            } else {
              if (this.coupon.type === this.couponTypeCategory) {
                appliedIn = this.couponTypeCategory
                if (this.coupon.categoryId && categories.includes(this.coupon.categoryId)) {
                  const discount = Number(this.coupon.descontoReais);
                  discountTotal += discount * item.Qty;
                }
              } else if(this.coupon.type === this.couponTypetypeSubcategory){
                appliedIn = this.couponTypetypeSubcategory
                if (this.coupon.subCategoryId && subCategories.includes(this.coupon.subCategoryId)) {
                  const discount = Number(this.coupon.descontoReais);
                  discountTotal += discount * item.Qty;
                }
              } else if(this.coupon.type === this.couponTypetypeProduct){
                appliedIn = this.couponTypetypeProduct
                if (this.coupon.productId && this.coupon.productId === item.product.productId) {
                  const discount = Number(this.coupon.descontoReais);
                  discountTotal += discount * item.Qty;
                }
              }
              if (!appliedIn) {
                const discount = Number(this.coupon.descontoReais);
                discountTotal += discount * item.Qty;
              }
            }
          })
        }

        model.Discount =  Number((Number(discountTotal.toFixed(2)) + Number(productPercentageDiscount.toFixed(2))).toFixed(2))
        model.TotalPrice =  Number((Number(model.Items.reduce((total, item) => (total + (item.productsSku.differentValue * item.Qty)), 0)) - model.Discount).toFixed(2))
        model.InstallmentsInterest = 0.00
        if (this.paymentMethod === this.creditCard &&  this.currentClient.creditInstallments && Number(this.currentClient.creditInstallments) > 3){
          const total = Number(model.TotalPrice) + Number(model.FretePrice)
          model.InstallmentsInterest = Number((Number((total * Math.pow(1 + this.taxaJuros / 100, this.currentClient.creditInstallments)).toFixed(2)) - total).toFixed(2))
        }
        orders.push(model)
      }

      await this.apiService
        .post(`order/add`, orders)
        .then(async (response) => {
          const { orders } = response
          const ordersCode = orders.map((order) => order.orderCode).join(',')
          await this.clearCart();
          await this.removeFreightQuote()
          await this.goToRouteOrder(`/checkout/orderstatus`, ordersCode)
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "sucess",
            title: "Pedido feito com sucesso",
            message: "Compra registrada!",
            trace: null,
          });
          this.loading = false;
        })
        .catch((err) => {
          const message = err.body.message;
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro",
            message: message ? message : "Erro ao finalizar compra",
            trace: null,
          });
          this.loading = false;
        });
    },

    calculateTotalInterestPerProduct(creditInstallments){
      let installmentsInterest = 0
      for (let i = 0; i < this.calculoFrete.length; i++) {
        const model = {
          Items: [],
          FretePrice: this.calculoFrete[i].melhorEnvio ? Number((this.calculoFrete[i].selectedShipping.price).toFixed(2)) : Number(this.calculoFrete[i].price.toFixed(2)),
        };
        let productPercentageDiscount = 0
        this.calculoFrete[i].productsSku.forEach((x) => {
          let percentage = 0;
          const [promotion] = x.product.promotions
          if (x.product.promotionStart && x.product.promotionEnd) {
            if (moment().isAfter(moment(x.product.promotionStart)) && moment().isBefore(moment(x.product.promotionEnd))) {
              percentage = x.product.percentage;
            }
          } else if (promotion) {
            percentage = promotion.promotion.discountType === this.percentual ? promotion.promotion.discount  : (promotion.promotion.discount / x.differentValue) * 100
          }
          productPercentageDiscount += (percentage / 100) * (x.differentValue * x.quantity);
          model.Items.push({
            ProductId: x.productId,
            SkuId: x.id,
            Price: (Number(x.differentValue) - (percentage / 100) * Number(x.differentValue).toFixed(2)),
            Qty: x.quantity,
            productsSku: x
          });
        });

        let discountTotal = 0

        if(this.percentageDiscountForApplicators > 0){
          model.Items.forEach((item) => {
            const discount = (item.Price / 100) * Number(this.percentageDiscountForApplicators);
            discountTotal += discount * item.Qty;
          })
        }

        if (this.coupon && this.coupon.benefit) {
          discountTotal = 0
          model.Items.forEach((item) => {
            const subCategories =
                item.productsSku.product && item.productsSku.product.subCategories && item.productsSku.product.subCategories.length > 0 ?
                    item.productsSku.product.subCategories && item.productsSku.product.subCategories.map((x) => x.subCategoryId) : []

            const categories =
                item.productsSku.product && item.productsSku.product.categories && item.productsSku.product.categories.length > 0 ?
                    item.productsSku.product.categories && item.productsSku.product.categories.map((x) => x.categoryId) : []

            let appliedIn = null
            if (this.coupon.benefit === this.benefitPercentual){
              if (this.coupon.type === this.couponTypeCategory) {
                appliedIn = this.couponTypeCategory
                if (this.coupon.categoryId && categories.includes(this.coupon.categoryId)) {
                  const discount = (item.Price / 100) * Number(this.coupon.descontoPercentual);
                  discountTotal += discount * item.Qty;
                }
              } else if(this.coupon.type === this.couponTypetypeSubcategory){
                appliedIn = this.couponTypetypeSubcategory
                if (this.coupon.subCategoryId && subCategories.includes(this.coupon.subCategoryId)) {
                  const discount = (item.Price / 100) * Number(this.coupon.descontoPercentual);
                  discountTotal += discount * item.Qty;
                }
              } else if(this.coupon.type === this.couponTypetypeProduct){
                appliedIn = this.couponTypetypeProduct
                if (this.coupon.productId && this.coupon.productId === item.product.productId) {
                  const discount = (item.Price / 100) * Number(this.coupon.descontoPercentual);
                  discountTotal += discount * item.Qty;
                }
              }
              if (!appliedIn) {
                const discount = (item.Price / 100) * Number(this.coupon.descontoPercentual);
                discountTotal += discount * item.Qty;
              }
            } else {
              if (this.coupon.type === this.couponTypeCategory) {
                appliedIn = this.couponTypeCategory
                if (this.coupon.categoryId && categories.includes(this.coupon.categoryId)) {
                  const discount = Number(this.coupon.descontoReais);
                  discountTotal += discount * item.Qty;
                }
              } else if(this.coupon.type === this.couponTypetypeSubcategory){
                appliedIn = this.couponTypetypeSubcategory
                if (this.coupon.subCategoryId && subCategories.includes(this.coupon.subCategoryId)) {
                  const discount = Number(this.coupon.descontoReais);
                  discountTotal += discount * item.Qty;
                }
              } else if(this.coupon.type === this.couponTypetypeProduct){
                appliedIn = this.couponTypetypeProduct
                if (this.coupon.productId && this.coupon.productId === item.product.productId) {
                  const discount = Number(this.coupon.descontoReais);
                  discountTotal += discount * item.Qty;
                }
              }
              if (!appliedIn) {
                const discount = Number(this.coupon.descontoReais);
                discountTotal += discount * item.Qty;
              }
            }
          })
        }

        model.Discount =  Number((Number(discountTotal.toFixed(2)) + Number(productPercentageDiscount.toFixed(2))).toFixed(2))
        model.TotalPrice =  Number((Number(model.Items.reduce((total, item) => (total + (item.productsSku.differentValue * item.Qty)), 0)) - model.Discount).toFixed(2))
        model.InstallmentsInterest = 0.00
        if (this.paymentMethod === this.creditCard &&  creditInstallments ? creditInstallments : this.currentClient.creditInstallments && Number(creditInstallments ? creditInstallments : this.currentClient.creditInstallments) > 3) {
          const total = Number(model.TotalPrice) + Number(model.FretePrice)
          model.InstallmentsInterest = Number((Number((total * Math.pow(1 + this.taxaJuros / 100, creditInstallments ? creditInstallments : this.currentClient.creditInstallments)).toFixed(2)) - total).toFixed(2))
        }
        installmentsInterest += model.InstallmentsInterest;
      }
      return Number(installmentsInterest.toFixed(2))
    },

    success(token) {
      this.pagarmetokens = token
      this.buy();
      return false;
    },

    fail(error) {
      this.loading = false;
      Vue.$globalEvent.$emit("onFlashMessage", {
        type: "error",
        title: "Erro",
        message: "Dados do cartão são inválidos!",
        trace: null,
      });
      return false;
    },

    async clearCart() {
      this.apiService.delete("cart/clear").then(() => {
        this.$root.$emit("changeBadge", this.badge);
      });
      this.componentKey += 1;
    },

    updateCart() {
      const cartFromLocalStorage = JSON.parse(
        localStorage.getItem("cartUser") || "[]"
      );
      if (cartFromLocalStorage) {
        this.cart = cartFromLocalStorage;
        this.badge = this.cart.reduce((total, item) => {
          return total + item.qty;
        }, 0);

        this.totalprice = this.cart.reduce((total, item) => {
          return total + item.qty * item.valor;
        }, 0);
      }
      //Option1- Evento Global
      this.$root.$emit("changeBadge", this.badge);
      //Option2- Coloca o badge no localhost para o navigation pegar
      localStorage.setItem("cartBadge", JSON.stringify(this.badge));
    },

    async calcularFrete() {
      const freightValueMelhorEnvio = this.calculoFrete.filter((x) => x.melhorEnvio && x.selectedShipping && x.selectedShipping.price).reduce((total, item) =>  Number(total) + Number(item.selectedShipping.price), 0)
      const standardShippingValue = this.calculoFrete.filter((x) => !x.melhorEnvio).reduce((total, item) =>  Number(total) + Number(item.price), 0)
      this.valorFrete = Number((standardShippingValue + freightValueMelhorEnvio).toFixed(2))
      this.totalOrder();
    },

    selectPaymentMethod(itemx) {
      this.paymentMethod = itemx;
    },

    selectDeliveryMethod(itemx) {
      this.deliveryMethod = itemx;
    },

    async getAddress(deliveryAddress) {
      this.loading = true;
      await this.apiService
        .get(`order/get-address?id=` + deliveryAddress.id)
        .then((response) => {
          this.addresses = response;
          if (this.addresses.length !== 0) {
            if (this.addressIdSelect) {
              this.endereco = this.addresses.find(
                (e) => e.id === this.addressIdSelect
              );
            } else {
              this.endereco = this.addresses.find((e) => e.addressType === 0);
            }
          } else {
            this.goToRoute("/address");
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
      this.loading = false;
    },

    async goToRouteOrder(route, ordersCode) {
      await this.$router.push({
        path: route,
        query: {ordersCode},
      });
    },

    async goToRoute(route) {
      if (this.$route.path != route) {
        await this.$router.push(route);
      }
    },

    async sendEmailPaymentAproved(email) {
      this.loading = true;
      await this.apiService
        .post("email/sendEmailPaymentAproved", email)
        .then((resp) => {
          this.loading = false;
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro",
            message: "Erro ao finalizar o pagamento",
            trace: null,
          });
          this.loading = false;
        });
      this.loading = false;
    },

    async sendEmailPaymentDenied(email) {
      this.loading = true;
      await this.apiService
        .post("email/sendEmailPaymentDenied", email)
        .then((resp) => {
          this.loading = false;
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro",
            message: "Erro ao finalizar o pagamento",
            trace: null,
          });

          this.loading = false;
        });
      this.loading = false;
    },

    async sendEmailPaymentWaiting(email) {
      this.loading = true;
      await this.apiService
        .post("email/sendEmailPaymentWaiting", email)
        .then((resp) => {
          this.loading = false;
        })
        .catch((err) => {
          Vue.$globalEvent.$emit("onFlashMessage", {
            type: "error",
            title: "Erro",
            message: "Erro ao realizar pedido!",
            trace: null,
          });

          this.loading = false;
        });
      this.loading = false;
    },

    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
  },
};
</script>
